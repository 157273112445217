(function() {
    'use strict';

    angular.module('flybrixAssemblyViewer').directive('fbaView', fbaView);

    fbaView.$inject = ['$window', '$timeout', 'fbaDesign', 'fbaViewerRenderers'];

    function edgeObject(radius, color) {
        var edgeGeometry = new THREE.CylinderGeometry(radius, radius, 1, 8, 1);
        var edge = new THREE.Mesh(edgeGeometry, new THREE.MeshBasicMaterial({ color: color }));
        var edgeGroup = new THREE.Group();
        edgeGroup.add(edge);
        edge.userData.cleanup = function() {
            edge.geometry.dispose();
            edge.material.dispose();
        };
        edgeGroup.userData.setScale = function(scale) {
            edge.scale.setY(scale);
            edge.position.setY(scale * 0.5);
        };
        return edgeGroup;
    }

    function axesObject(radius) {
        var xAxis = edgeObject(radius, 'red');
        var yAxis = edgeObject(radius, 'green');
        var zAxis = edgeObject(radius, 'blue');
        xAxis.rotateZ(Math.PI * -0.5);
        zAxis.rotateX(Math.PI * 0.5);
        var object = new THREE.Group();
        object.add(xAxis);
        object.add(yAxis);
        object.add(zAxis);
        object.userData.setScale = function(scale) {
            object.children.forEach(function (child) {
                child.userData.setScale(scale);
            });
        };
        return object;
    }

    function fbaView($window, $timeout, fbaDesign, fbaViewerRenderers) {
        return {
            restrict: 'E',
            scope: {
                backgroundColor: '=?',
                axesScale: '=?',
                rotation: '=?',
                design: '=',
                hlColor: '=',
                step: '=',
                hlWeight: '=',
                maxCameraDistance: '=?',
                minCameraDistance: '=?',
                initialCameraDistance: '=?',
            },
            template: '',
            
            link: function (scope, element, attrs) {

                var scene = new THREE.Scene();
                var cleanupScene = function () {
                    scene.traverse(function (item) {
                        if (item && item.userData && item.userData.cleanup) {
                            item.userData.cleanup();
                        }
                    });
                };
                var rendererEntry = fbaViewerRenderers.add();
                var renderer = rendererEntry.renderer;
                element.parent()[0].appendChild(renderer.domElement);
                
                var camera = new THREE.PerspectiveCamera(70, 1, 0.1, 1000);
                scene.add(camera);
                scene.add(new THREE.AmbientLight(0xffffff, 0.6));

                var partsMount = new THREE.Group();
                partsMount.rotateX(Math.PI * -0.5);
                scene.add(partsMount);

                var parts = new THREE.Group();
                partsMount.add(parts);

                var axes = axesObject(0.8);
                scene.add(axes);

                var pointLight = new THREE.PointLight(0xffffff, 0.6);
                camera.add(pointLight);
                camera.position.set(50, 0.75*50, 1.25*50);
                
                var controls = new THREE.OrbitControls(camera, renderer.domElement);

                controls.enabled = true;
                controls.enableDumping = true;
                controls.enableZoom = true;
                controls.enablePan = false;
                controls.zoomSpeed = 1;
                controls.rotateSpeed = 0.25;
                controls.minDistance = 50;
                controls.maxDistance = 250;
                controls.target.set(0, 0, 0);

                var animate = true;
                
                function resize() {
                    var height = element.parent()[0].clientHeight;
                    var width = element.parent()[0].clientWidth;
                    camera.aspect = width / height;
                    camera.updateProjectionMatrix();
                    renderer.setSize(width, height);
                };
                angular.element($window).bind('resize', function(){resize();});
            

                scope.$on('$destroy', function() {
                    fbaViewerRenderers.free(rendererEntry.key);
                    cleanupScene();
                    renderer = null;
                    animate = false;
                    angular.element($window).unbind('resize');
                });

                scope.$watch('backgroundColor', function(v) {
                    renderer.setClearColor(parseInt(v,16) || 0, 1);
                });

                scope.$watch('axesScale', function(v) {
                    if (!v) {
                        axes.visible = false;
                    } else {
                        axes.userData.setScale(v);
                        axes.visible = true;
                    }
                });

                scope.$watchGroup(['design', 'step', 'hlColor', 'hlWeight'], function(design) {
                    parts.children.splice(0, parts.children.length);
                    parts.add(fbaDesign(scope.design, {
                        color: scope.hlColor || 0,
                        step: Number(scope.step) || 0,
                        weight: Number(scope.hlWeight) || 0,
                    }));
                    loop();
                })

                scope.$watch('initialCameraDistance', function(v) {
                    var d = parseInt(v,10) || 70;
                    camera.position.set(d, 0.75*d, 1.25*d);
                });
                
                scope.$watch('maxCameraDistance', function(v) {
                    controls.maxDistance = parseInt(v,10) || 250;
                });

                scope.$watch('minCameraDistance', function(v) {
                    controls.minDistance = parseInt(v,10) || 50;
                });

                scope.$watch('rotation', function(v) {
                    parts.setRotationFromQuaternion(v || new THREE.Quaternion());
                });

                function loop() {
                    if (!animate) {
                        return;
                    }
                    resize();
                    controls.update();
                    renderer.render(scene, camera);
                    requestAnimationFrame(loop);
                }
            }
        };
    };
    
}());
