(function() {
    'use strict';

    function modelFactory(juggernaut, flatXWing, angleArmed, dualSided, flyingV, flat6, flat8) {
        
         var models = [
            { id:"m0", name: "Juggernaut", type: "4", img_name: "juggernaut", json_string: juggernaut },
            { id:"m1", name: "X Wing", type: "4", img_name: "flat-x-wing", json_string: flatXWing },
            { id:"m2", name: "Angle Armed", type: "4", img_name: "angle-armed", json_string: angleArmed },
            { id:"m3", name: "Dual Sided", type: "4", img_name: "dual-sided", json_string: dualSided },
            { id:"m4", name: "Flying V", type: "4", img_name: "flying-v", json_string: flyingV },
            { id:"m5", name: "Flat Six", type: "6", img_name: "flat-6", json_string: flat6 },
            { id:"m6", name: "Flat Eight", type: "8", img_name: "flat-8", json_string: flat8 }
        ];
        
        return {
            getAll: function() { return models;},
        };
    };
    
    angular.module('flybrixAssemblyViewer').factory('flybrixModels', ['juggernaut', 'flatXWing', 'angleArmed', 'dualSided', 'flyingV', 'flat6', 'flat8', modelFactory]);
    
}());
