(function() {
    'use strict';

    // all geometry units are in mm
    
    // Plates are shrunk by 0.1mm on each sides
    function to_mm(brick_units) {
        return new THREE.Vector3(
            (brick_units.x * 8 - 0.2) / 8,
            (brick_units.y * 8 - 0.2) / 8,
            brick_units.z );
    }
  
    function prefabsFactory($q, stlBufferGeometry) {

        var base = 'plate:base';
        var knob = 'plate:knob';
        var windshield = 'windshield:glass';
        var fin = 'tailfin:fin';
        var motor_ring = 'motor:ring';
        var motor_propeller = 'motor:propeller';
        var arm_double = 'motor:arm_double';
        var arm_angled = 'motor:arm_angled';
        var motor_arrow = 'motor:arrow';
        var stud = 'stud';
        var frame = 'frame4x4';
        var unit_cylinder = 'unit_cylinder';
        var fb_arm1_base = 'fb_arm1_base';

        var cylinder_sides = 32;
        
        var PLATE_WIDTH = 8; // before shrink
        var PLATE_HEIGHT = 3.2;
        var KNOB_DIAMETER = 4.8;
        var KNOB_HEIGHT = 1.8;
        var STUD_BODY_DIAMETER = 7.8;
        var STUD_BODY_THICKNESS = 1.00;
        var STUD_BASE_DIAMETER = 6.5;
        var STUD_BASE_THICKNESS = 2.2;
        
        // load in STL geometries
        var geometries = stlBufferGeometry.getPromises();
        
        // generate base geometry used to build plates
        var q_base = $q.defer();
        q_base.resolve(new THREE.BoxGeometry(PLATE_WIDTH, PLATE_WIDTH, PLATE_HEIGHT).translate(0, 0, PLATE_HEIGHT/2));
        geometries[base] = q_base.promise;  
        
        // generate knob geometry used to build plates
        var q_knob = $q.defer();
        q_knob.resolve(new THREE.CylinderGeometry(KNOB_DIAMETER/2, KNOB_DIAMETER/2, KNOB_HEIGHT, cylinder_sides).rotateX(Math.PI/2).translate(0, 0, KNOB_HEIGHT/2));
        geometries[knob] = q_knob.promise; 
        
        // generate unit cyinder geometry
        var q_unit_cylinder = $q.defer();
        q_unit_cylinder.resolve(new THREE.CylinderGeometry(1/2, 1/2, 1, cylinder_sides).rotateX(Math.PI/2).translate(0, 0, 1/2) );
        geometries[unit_cylinder] = q_unit_cylinder.promise; 
        
        // generate 1x1 round stud part
        var q_stud = $q.defer();
        var geom = new THREE.CylinderGeometry(STUD_BASE_DIAMETER/2, STUD_BASE_DIAMETER/2, STUD_BASE_THICKNESS, cylinder_sides).rotateX(Math.PI/2).translate(0, 0, STUD_BASE_THICKNESS/2);
        geom.merge(new THREE.CylinderGeometry(STUD_BODY_DIAMETER/2, STUD_BODY_DIAMETER/2, STUD_BODY_THICKNESS, cylinder_sides).rotateX(Math.PI/2).translate(0, 0, STUD_BASE_THICKNESS + STUD_BODY_THICKNESS/2) );    
        geom.merge(new THREE.CylinderGeometry(KNOB_DIAMETER/2, KNOB_DIAMETER/2, KNOB_HEIGHT, cylinder_sides).rotateX(Math.PI/2).translate(0, 0, STUD_BASE_THICKNESS + STUD_BODY_THICKNESS + KNOB_HEIGHT/2) );
        q_stud.resolve(geom);
        geometries[stud] = q_stud.promise; 
        
        // generate the motor ring
        var q_motor_ring = $q.defer();
        var d_outer = 12;
        var d_inner = 8.5;
        var motor_ring_shape = new THREE.Shape(); //must use CCW winding!
        motor_ring_shape.absellipse (0, 0, d_outer/2, d_outer/2, 0, 2*Math.PI);
        var motor_ring_hole = new THREE.Path(); //must use CCW winding!
        motor_ring_hole.absellipse (0, 0, d_inner/2, d_inner/2, 0, 2*Math.PI);
        motor_ring_shape.holes.push(motor_ring_hole);
        q_motor_ring.resolve(new THREE.ExtrudeGeometry( motor_ring_shape, {curveSegments:cylinder_sides, steps: 1, amount: 5, bevelEnabled: false}) );
        geometries[motor_ring] = q_motor_ring.promise;  
        
        var prefabs = {};
        
        function platePrefab(width, length) {
            var result = {
                parts: [
                    {
                        geom: base,
                        scale: to_mm(new THREE.Vector3(length, width, 1)),
                    },
                ],
            };
            for (var i = 0; i < width; ++i) {
                var y = (i * 2 + 1 - width) * 4;
                for (var j = 0; j < length; ++j) {
                    var x = (j * 2 + 1 - length) * 4;
                    result.parts.push({
                        geom: knob,
                        position: new THREE.Vector3(x, y, PLATE_HEIGHT),
                    });
                }
            }
            return result;
        }
        
        prefabs.plate_1x4_mid_flat = {
            parts: [
                {
                    geom: base,
                    scale: to_mm(new THREE.Vector3(4, 1, 1)),
                },
                {
                    geom: knob,
                    position: new THREE.Vector3(-12, 0, PLATE_HEIGHT),
                },
                {
                    geom: knob,
                    position: new THREE.Vector3(12, 0, PLATE_HEIGHT),
                },
            ]
        };
               
        // generate a nice extruded frame plate geometry
        var q_frame = $q.defer();
        var frame_outer = (4*8 - 0.2)/2;
        var frame_inner = (2*8 + 0.2)/2;
        var frame_shape = new THREE.Shape(); //must use CCW winding!
        frame_shape.moveTo(-frame_outer,-frame_outer);
        frame_shape.lineTo( frame_outer,-frame_outer);
        frame_shape.lineTo( frame_outer, frame_outer);
        frame_shape.lineTo(-frame_outer, frame_outer);
        var frame_hole = new THREE.Path(); //must use CCW winding!
        frame_hole.moveTo(-frame_inner,-frame_inner);
        frame_hole.lineTo( frame_inner,-frame_inner);
        frame_hole.lineTo( frame_inner, frame_inner);
        frame_hole.lineTo(-frame_inner, frame_inner);
        frame_shape.holes.push(frame_hole);
        q_frame.resolve(new THREE.ExtrudeGeometry(frame_shape, {curveSegments:4, steps: 1, amount: PLATE_HEIGHT, bevelEnabled: false}) );
        geometries[frame] = q_frame.promise; 
        
        prefabs.frame_4x4 = {
            parts: [
                {
                    geom: frame,
                    position: new THREE.Vector3(0, 0, 0),
                },
            ],
        };
        for (var i = -12; i <= 12; i += 8) {
            for (var j = -12; j <= 12; j += 8) {
                if (Math.abs(i) < 12 && Math.abs(j) < 12) {
                    continue;
                }
                prefabs.frame_4x4.parts.push({
                    geom: knob,
                    position: new THREE.Vector3(i, j, PLATE_HEIGHT),
                });
            }
        }

        function buildMotorArm() {
            var q_arm_standard = $q.defer();
            var length = 49.72;
            var center = 53.38;
            var angle = 47.77 * Math.PI/180.0;
            var d_outer = 10.88;
            var d_inner = 8.5;
            var arm_standard_shape = new THREE.Shape(); //must use CCW winding!
            arm_standard_shape.moveTo(24-0.1, -4+0.1);
            arm_standard_shape.lineTo(24-0.1, 4-0.1);
            arm_standard_shape.absellipse(24-0.1 - center, 0, d_outer/2, d_outer/2, angle, 2*Math.PI -angle);
            arm_standard_shape.lineTo(24-0.1 , -4+0.1);
            var arm_standard_hole = new THREE.Path(); //must use CCW winding!
            arm_standard_hole.absellipse(24-0.1 - center, 0, d_inner/2, d_inner/2, 0, 2*Math.PI);
            arm_standard_shape.holes.push(arm_standard_hole);
            q_arm_standard.resolve(new THREE.ExtrudeGeometry( arm_standard_shape, {curveSegments:cylinder_sides, steps: 1, amount: PLATE_HEIGHT, bevelEnabled: false}) );
            geometries[fb_arm1_base] = q_arm_standard.promise;
            var result = {
                parts: [
                    {
                        geom: fb_arm1_base,
                    },
                    {
                        geom: motor_ring,
                        scale: new THREE.Vector3(d_outer/12, d_outer/12, (5-PLATE_HEIGHT)/5),
                        position: new THREE.Vector3(24-0.1-center, 0, PLATE_HEIGHT),
                    },
                ],
            };
            for (var x = 4; x < 6*8; x += 8) {
                result.parts.push({
                    geom: knob,
                    position: new THREE.Vector3( 24-x , 0, PLATE_HEIGHT),
                });
            }
            return result;
        }
        prefabs.motor_arm = buildMotorArm();
        
        // generate the fin geometry
        var q_fin = $q.defer();
        var fin_shape = new THREE.Shape(); //must use CCW winding!
        fin_shape.moveTo(   0,     0);
        fin_shape.lineTo( 7.8,     0);
        fin_shape.lineTo(23.10,  6.6);
        fin_shape.lineTo(23.10, 19.1);
        fin_shape.lineTo(   16, 19.1);
        fin_shape.lineTo(    0,  5.4);
        q_fin.resolve(new THREE.ExtrudeGeometry(fin_shape, {curveSegments:8, steps: 1, amount: 1.6, bevelEnabled: false}).rotateX(Math.PI/2).rotateZ(-Math.PI/2).translate(0.8,3.9,0));
        geometries[fin] = q_fin.promise; 
        
        prefabs.fin = {
            parts: [
                {
                    geom: base,
                    scale: to_mm(new THREE.Vector3(2, 1, 1)),
                },
                {
                    geom: fin,
                }
            ],
        };
        
        // generate the motor_arrow geometry
        var q_motor_arrow = $q.defer();
        var d_o = 60;
        var d_i = 58;
        var ma_angle = 165 * Math.PI/180.0;
        var motor_arrow_shape = new THREE.Shape(); //must use CCW winding!
        motor_arrow_shape.moveTo(-5, (d_o + d_i)/4);
        motor_arrow_shape.lineTo(0, d_i/2 - 2);
        motor_arrow_shape.absellipse(0, 0, d_i/2, d_i/2, Math.PI/2, Math.PI/2 - ma_angle, true);
        motor_arrow_shape.absellipse(0, 0, d_o/2, d_o/2, Math.PI/2 - ma_angle, Math.PI/2);
        motor_arrow_shape.lineTo(0, d_o/2 + 2);
        q_motor_arrow.resolve(new THREE.ExtrudeGeometry(motor_arrow_shape, {curveSegments:cylinder_sides, steps: 1, amount: 1.6, bevelEnabled: false}).rotateZ(45*Math.PI/180.0));
        geometries[motor_arrow] = q_motor_arrow.promise; 

        prefabs.windshield = {
            parts: [
                {
                    geom: windshield,
                    opacity: 0.5,
                },
                {
                    geom: knob,
                    opacity: 0.5,
                    position: new THREE.Vector3(12, -4, 20),
                    scale: new THREE.Vector3(1, 1, 0.6),
                },
                {
                    geom: knob,
                    opacity: 0.5,
                    position: new THREE.Vector3(-12, -4, 20),
                    scale: new THREE.Vector3(1, 1, 0.6),
                }
            ],
        };

        prefabs.motor_arm_double = {
            parts: [
                {
                    geom: arm_double
                }, {
                    geom: knob,
                    position: new THREE.Vector3(12, 0, PLATE_HEIGHT),
                }, {
                    geom: knob,
                    position: new THREE.Vector3(-12, 0, PLATE_HEIGHT),
                }, {
                    geom: knob,
                    position: new THREE.Vector3(20, 0, PLATE_HEIGHT),
                }, {
                    geom: knob,
                    position: new THREE.Vector3(-20, 0, PLATE_HEIGHT),
                }, {
                    geom: motor_ring,
                    position: new THREE.Vector3(-48, -39.837, 0),
                }, {
                    geom: motor_ring,
                    position: new THREE.Vector3(48, -39.837, 0),
                }
            ],
        };

        prefabs.motor_arm_angled_left = {
            parts: [
                {
                    geom: base,
                    scale: to_mm(new THREE.Vector3(3, 1, 1)),
                }, {
                    geom: knob,
                    position: new THREE.Vector3( 0, 0, PLATE_HEIGHT),
                }, {
                    geom: knob,
                    position: new THREE.Vector3(-8, 0, PLATE_HEIGHT),
                }, {
                    geom: arm_angled,
                    position: new THREE.Vector3(8, 0, 0),
                    rotation: {
                        "z": 45,
                    },
                }, {
                    geom: motor_ring,
                    position: new THREE.Vector3(46, 38, 18),
                }
            ]
        };

        prefabs.motor_arm_angled_right = {
            parts: [
                {
                    geom: base,
                    scale: to_mm(new THREE.Vector3(3, 1, 1)),
                }, {
                    geom: knob,
                    position: new THREE.Vector3( 0, 0, PLATE_HEIGHT),
                }, {
                    geom: knob,
                    position: new THREE.Vector3(-8, 0, PLATE_HEIGHT),
                }, {
                    geom: arm_angled,
                    position: new THREE.Vector3(8, 0, 0),
                    rotation: {
                        "z": -45,
                    },
                }, {
                    geom: motor_ring,
                    position: new THREE.Vector3(46, -38, 18),
                }
            ]
        };

        prefabs.prop_cw = prop_cw_pattern(new THREE.Vector3(-29.35, 0, 0));
        prefabs.prop_ccw = prop_ccw_pattern(new THREE.Vector3(-29.35, 0, 0));
        prefabs.motor = motor_pattern(new THREE.Vector3(-29.35, 0, 0));
        prefabs.prop_cw_double_a = prop_cw_pattern(new THREE.Vector3(-48, -39.837, 0));
        prefabs.prop_ccw_double_a = prop_ccw_pattern(new THREE.Vector3(-48, -39.837, 0));
        prefabs.motor_double_a = motor_pattern(new THREE.Vector3(-48, -39.837, 0));
        prefabs.prop_cw_double_b = prop_cw_pattern(new THREE.Vector3(48, -39.837, 0));
        prefabs.prop_ccw_double_b = prop_ccw_pattern(new THREE.Vector3(48, -39.837, 0));
        prefabs.motor_double_b = motor_pattern(new THREE.Vector3(48, -39.837, 0));
        prefabs.prop_cw_angled_l = prop_cw_pattern(new THREE.Vector3(46, 38, 18));
        prefabs.prop_ccw_angled_l = prop_ccw_pattern(new THREE.Vector3(46, 38, 18));
        prefabs.motor_angled_l = motor_pattern(new THREE.Vector3(46, 38, 18));
        prefabs.prop_cw_angled_r = prop_cw_pattern(new THREE.Vector3(46, -38, 18));
        prefabs.prop_ccw_angled_r = prop_ccw_pattern(new THREE.Vector3(46, -38, 18));
        prefabs.motor_angled_r = motor_pattern(new THREE.Vector3(46, -38, 18));

        function motor_pattern(position) {
            return {
                parts: [
                    {
                        geom: unit_cylinder,
                        position: new THREE.Vector3(0, 0, -3.5).add(position),
                        scale: new THREE.Vector3(8.5, 8.5, 20),
                        color: 0x999999,
                    }, {
                        geom: unit_cylinder,
                        position: new THREE.Vector3(0, 0, 15.5).add(position),
                        scale: new THREE.Vector3(0.8, 0.8, 4),
                        color: 0x999999,
                    }
                ],
                motor_base: position.clone(),
            };
        }

        function prop_cw_pattern(position) {
            return {
                parts: [
                    {
                        geom: unit_cylinder,
                        position: new THREE.Vector3(0, 0, 18.5).add(position),
                        scale: new THREE.Vector3(2, 2, 6),
                    }, {
                        geom: motor_propeller,
                        position: new THREE.Vector3(0, 0, 22.5).add(position),
                    }, {
                        geom: motor_arrow,
                        position: new THREE.Vector3(0, 0, 23.5).add(position),
                        color: 0xA92C29,
                    }, {
                        geom: motor_arrow,
                        position: new THREE.Vector3(0, 0, 23.5).add(position),
                        rotation: {
                            "z": 180,
                        },
                        color: 0x02346F,
                    }
                ],
            };
        }

        function prop_ccw_pattern(position) {
            return {
                parts: [
                    {
                        geom: unit_cylinder,
                        position: new THREE.Vector3(0, 0, 18.5).add(position),
                        scale: new THREE.Vector3(2, 2, 6),
                    }, {
                        geom: motor_propeller,
                        position: new THREE.Vector3(0, 0, 22.5).add(position),
                        rotation: {
                            "y": 180,
                        },
                    }, {
                        geom: motor_arrow,
                        position: new THREE.Vector3(0, 0, 23.5).add(position),
                        rotation: {
                            "x": 180,
                        },
                        color: 0x171B26,
                    }, {
                        geom: motor_arrow,
                        position: new THREE.Vector3(0, 0, 23.5).add(position),
                        rotation: {
                            "x": 180,
                            "z": 180,
                        },
                        color: 0xD6E2D5,
                    }
                ],
            }
        }

        prefabs.pcb = {
            parts: [
                {
                    geom: base,
                    scale: new THREE.Vector3(4, 6, 0.3),
                    position: new THREE.Vector3(0, 0, 1),
                    color: 0x880000,
                }, {
                    geom: base,
                    scale: new THREE.Vector3(0.3, 3, 2),
                    position: new THREE.Vector3(14, 0, 1.8),
                    color: 0,
                }, {
                    geom: base,
                    scale: new THREE.Vector3(0.3, 3, 2),
                    position: new THREE.Vector3(-14, 0, 1.8),
                    color: 0,
                }, {
                    geom: base,
                    scale: new THREE.Vector3(1.4, 0.6, 0.7),
                    position: new THREE.Vector3(0, 12, 1.2),
                    color: 0xbbbbbb,
                }, {
                    geom: stud,
                    position: new THREE.Vector3(12, 20, 0),
                }, {
                    geom: stud,
                    position: new THREE.Vector3(12, -20, 0),
                }, {
                    geom: stud,
                    position: new THREE.Vector3(-12, 20, 0),
                }, {
                    geom: stud,
                    position: new THREE.Vector3(-12, -20, 0),
                }
            ],
        };

        prefabs.battery = {
            parts: [
                {
                    geom: base,
                    scale: new THREE.Vector3(2.5, 4.75, 2.5625),
                    color: 0xbbbbbb,
                },
                {
                    color: 0xffffff,
                    opacity: 0.6,
                    geom: unit_cylinder,
                    position: new THREE.Vector3(0, 0, -1.8),
                    scale: new THREE.Vector3(16, 16, 3),
                }
            ],
        };

        prefabs.sticker = {
            parts: [
                {
                    geom: base,
                    scale: to_mm(new THREE.Vector3(2, 2, 1)),
                },
                {
                    color: 0xffffff,
                    opacity: 0.6,
                    geom: unit_cylinder,
                    position: new THREE.Vector3(0, 0, 3),
                    scale: new THREE.Vector3(16, 16, 3),
                }
            ],
        };

        prefabs.joint_a = {
            parts: [
                {
                    geom: base,
                    scale: to_mm(new THREE.Vector3(1, 2, 1)),
                    position: new THREE.Vector3(4, 8, 0),
                },
                {
                    geom: knob,
                    position: new THREE.Vector3(4, 4, PLATE_HEIGHT),
                },
                {
                    geom: knob,
                    position: new THREE.Vector3(4, 12, PLATE_HEIGHT),
                },
                {
                    geom: unit_cylinder,
                    scale: new THREE.Vector3(6.2, 6.2, PLATE_HEIGHT),
                },
            ]
        };

        prefabs.joint_b = {
            parts: [
                {
                    geom: base,
                    scale: to_mm(new THREE.Vector3(1, 2, 1)),
                    position: new THREE.Vector3(-4, 8, 0),
                },
                {
                    geom: knob,
                    position: new THREE.Vector3(-4, 4, PLATE_HEIGHT),
                },
                {
                    geom: knob,
                    position: new THREE.Vector3(-4, 12, PLATE_HEIGHT),
                },
            ]
        };


        function deriveMaterial(material, color, opacity) {
            if (color === undefined && opacity === undefined) {
                return material;
            }
            material = material.clone();
            if (color !== undefined) {
                material.color = new THREE.Color(color);
            }
            if (opacity !== undefined) {
                material.opacity = opacity;
                material.transparent = true;
            }
            return material;
        }

        function prefabToObject(prefab, material, highlightMaterial) {
            if (prefab.geom) {
                return getGeometry(prefab.geom).then(function(geometry) {
                    var mesh = new THREE.Mesh(geometry, material);
                    if (highlightMaterial) {
                        var group = new THREE.Group();
                        group.add(mesh);
                        var wireframe = new THREE.EdgesGeometry(geometry);
                        group.add(new THREE.LineSegments(wireframe, highlightMaterial));
                        mesh = group;
                    }
                    return mesh;
                });
            }

            return $q.all(prefab.parts.map(partToMesh)).then(function(parts) {
                var group = new THREE.Group();
                parts.forEach(function(part) {
                    group.add(part);
                });
                return group;
            });

            function partToMesh(part) {
                return getGeometry(part.geom).then(function(geometry) {
                    var mesh = new THREE.Mesh(
                        geometry,
                        deriveMaterial(material, part.color, part.opacity)
                    );
                    if (highlightMaterial) {
                        var group = new THREE.Group();
                        group.add(mesh);
                        var wireframe = new THREE.EdgesGeometry(geometry);
                        group.add(new THREE.LineSegments(wireframe, highlightMaterial));
                        mesh = group;
                    }
                    if (part.scale) {
                        mesh.scale.copy(part.scale);
                    }
                    if (part.position) {
                        mesh.position.copy(part.position);
                    }
                    var rot = part.rotation;
                    if (rot) {
                        if (rot.z) {
                            mesh.rotateZ(rot.z * Math.PI / 180)
                        }
                        if (rot.y) {
                            mesh.rotateY(rot.y * Math.PI / 180)
                        }
                        if (rot.x) {
                            mesh.rotateX(rot.x * Math.PI / 180)
                        }
                    }
                    return mesh;
                });
            }
        }
   
        var stlLoader = new THREE.STLLoader();
        
        function addPart(name, url) {
            if (geometries[name]) {
                return;
            }
            var q = $q.defer();
            console.log("Loading geometry from STL file -- consider adding to 'stl-buffer-geometry.js' ...");
            stlLoader.load(url, function(v){q.resolve(v); console.log(name, JSON.stringify(v));}, q.notify, q.reject)
            geometries[name] = q.promise;
        }

        function getGeometry(name) {
            return geometries[name] || $q.reject('Requested geometry missing: ' + name);
        }

        return {
            addPart: addPart,
            loadPrefab: function(material, name, highlightMaterial) {
                if (name in geometries) {
                    return prefabToObject({geom: name}, material, highlightMaterial);
                }
                if (!(name in prefabs)) {
                    return $q.reject('Requested prefab missing: ' + name);
                }
                return prefabToObject(prefabs[name], material, highlightMaterial);
            },
            loadPlate: function(material, width, length, highlightMaterial) {
                return prefabToObject(
                    platePrefab(width, length),
                    material,
                    highlightMaterial
                );
            },
            getMotorBase: function(name) {
                var motor_base = (prefabs[name] || {}).motor_base;
                if (!motor_base) {
                    return null;
                }
                return motor_base.clone();
            },
            getChannelLocation: function(id) {
                var x = id % 2;
                var y = (id - x) / 2;
                return {
                    position: new THREE.Vector3(28 * x - 14, 6 * y - 9, 8.2),
                };
            }
        };
    };
    
    angular.module('flybrixAssemblyViewer').factory('fbaPrefabs', ['$q', 'stlBufferGeometry', prefabsFactory]);
}());
