(function() {
    'use strict';

    angular.module('flybrixAssemblyViewer').factory('fbaColors', colorsFactory);

    function colorsFactory() {
        var options = {
            'dark-blue': 0x02346F,
            'light-blue': 0x3791AB,
            'purple': 0x400A66,
            'black': 0x171B26,
            'gray': 0x757F7E,
            'white': 0xD6E2D5,
            'dark-pink': 0xA0285B,
            'pink': 0xE35E90,
            'light-pink': 0xC8859B,
            'red': 0xA92C29,
            'orange': 0xEC6121,
            'yellow': 0xFFCB3F,
            'brown': 0x4B251C,
            'dark-green': 0x2D3125,
            'green': 0x0D562B,
            'light-green': 0x90AE2E,
        };

        var prefabs = {
            plate_1x4_mid_flat: options['light-green'],
            frame_4x4: options['yellow'],
            fin: options['dark-blue'],
            windshield: options['white'],
            motor_arm: options['light-blue'],
            motor_arm_double: options['light-green'],
            motor_arm_angled_left: options['red'],
            motor_arm_angled_right: options['red'],
            prop_cw: options['gray'],
            prop_ccw: options['gray'],
            prop_cw_double_a: options['gray'],
            prop_ccw_double_a: options['gray'],
            prop_cw_double_b: options['gray'],
            prop_ccw_double_b: options['gray'],
            prop_cw_angled_l: options['gray'],
            prop_ccw_angled_l: options['gray'],
            prop_cw_angled_r: options['gray'],
            prop_ccw_angled_r: options['gray'],
            pcb: options['orange'],
            battery: options['gray'],
            sticker: options['yellow'],
            joint_a: options['green'],
            joint_b: options['green'],
            stud: options['red'],
        };

        var plates = {
            '2x4': options['yellow'],
            '1x12': options['dark-blue'],
            '1x8': options['dark-blue'],
            '1x6': options['light-green'],
            '1x4': options['dark-pink'],
            '1x3': options['yellow'],
        }

        function colorCode(name) {
            return options[name] || null;
        }

        function wlToName(w, l) {
            w = w || 1;
            l = l || 1;
            if (w > l) {
                return l + 'x' + w;
            }
            return w + 'x' + l;
        }

        function getPaint(part) {
            return options[part.color] ||
                prefabs[part.name] ||
                plates[wlToName(part.width, part.length)] ||
                0xFF00FF;
        }

        return {
            getPaint: getPaint,
            list: function() {
                return Object.keys(options);
            },
            colorCode: colorCode,
        };
    }

}());
