(function () {
    'use strict';

    angular.module('flybrixAssemblyViewer').factory('fbaViewerRenderers', fbaViewerRenderers);

    fbaViewerRenderers.$inject = ['$window'];

    function fbaViewerRenderers($window) {
        var renderers = [];
        var cleanupThreshold = 5;
        var newestKey = 0;
        var generateKey = function () {
            return (++newestKey).toString();
        };
        var getRenderersEntry = function (key) {
            return renderers.find(function (item) {
                return item.key === key;
            });
        };
        var getRenderer = function () {
            var key = generateKey();
            var rendererEntry = getRenderersEntry(null);
            if (rendererEntry) {
                rendererEntry.key = key;
            } else {
                rendererEntry = {
                    renderer: new THREE.WebGLRenderer({
                        preserveDrawingBuffer: true,
                    }),
                    key: key,
                };
                renderers.push(rendererEntry)
            }
            var renderer = rendererEntry.renderer;
            renderer.setPixelRatio($window.devicePixelRatio);
            renderer.setClearColor(0, 1);
            renderer.setSize(1, 1);

            return rendererEntry;
        };
        var freeRenderer = function (key) {
            var renderer = getRenderersEntry(key);
            if (renderer) {
                renderer.key = null;
            }
            if (renderers.length > cleanupThreshold) {
                renderers = renderers.filter(function (item) {
                    if (item.key) {
                        return true;
                    }
                    //https://stackoverflow.com/questions/21548247/clean-up-threejs-webgl-contexts
                    item.renderer.forceContextLoss();
                    item.renderer.context = null;
                    item.renderer.domElement = null;
                    item.renderer = null;
                    return false;
                });
            }
        };

        return {
            add: getRenderer,
            free: freeRenderer,
        };
    }
}());
