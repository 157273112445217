(function() {
    'use strict';
    
var flatXWing =
[{
    "step": 0,
    "position": {
        "x": 0,
        "z": -3
    },
    "name": "frame_4x4",
    "width": 4
},
{
    "step": 0,
    "position": {
        "y": 1.5,
        "z": -2
    },
    "plate": true,
    "length": 8
},
{
    "step": 0,
    "position": {
        "y": -1.5,
        "z": -2
    },
    "plate": true,
    "length": 8,
    "text": "Start by connecting two 1x8 plates to the sides of the 4x4 open frame piece."
},
{
    "step": 1,
    "position": {
        "x": -3.75,
        "y": -3.665,
        "z": -1
    },
    "rotation": {
        "yaw": 60
    },
    "name": "motor_arm"
},
{
    "step": 1,
    "position": {
        "x": -3.75,
        "y": 3.665,
        "z": -1
    },
    "rotation": {
        "yaw": -60
    },
    "name": "motor_arm"
},
{
    "step": 1,
    "position": {
        "x": 3.75,
        "y": -3.665,
        "z": -1
    },
    "rotation": {
        "yaw": -240
    },
    "name": "motor_arm"
},
{
    "step": 1,
    "position": {
        "x": 3.75,
        "y": 3.665,
        "z": -1
    },
    "rotation": {
        "yaw": 240
    },
    "name": "motor_arm",
    "text": "Add a straight motor arm to the second knob in on each 1x8 plate. Rotate the arms outward."
},
{
    "step": 2,
    "rotation": {
        "yaw": -90
    },
    "name": "pcb",
    "position": {
        "x": 0,
        "y": 0,
        "z": 0
    },
    "text": "Attach the flightboard at the ends of the motor arms."
},
{
    "step": 3,
    "position": {
        "x": 2.5,
        "y": 1.5,
        "z": 1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 3,
    "position": {
        "x": 2.5,
        "y": -1.5,
        "z": 1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 3,
    "position": {
        "x": -2.5,
        "y": 1.5,
        "z": 1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 3,
    "position": {
        "x": -2.5,
        "y": -1.5,
        "z": 1
    },
    "name": "stud",
    "part_number": "LEGO-4073",
    "text": "Attach four 1x1 round pieces to the knobs on the flightboard."
},
{
    "step": 4,
    "position": {
        "x": 2.5,
        "z": 2
    },
    "plate": true,
    "width": 4
},
{
    "step": 4,
    "position": {
        "x": -2.5,
        "z": 2
    },
    "plate": true,
    "width": 4,
    "text": "Place two 1x4 plates across the 1x1 pieces."
},
{
    "step": 5,
    "position": {
        "y": 0.5,
        "z": 3
    },
    "rotation": {
        "yaw": 90
    },
    "plate": true,
    "width": 6
},
{
    "step": 5,
    "position": {
        "y": -0.5,
        "z": 3
    },
    "rotation": {
        "yaw": 990
    },
    "plate": true,
    "width": 6
},
{
    "step": 5,
    "position": {
        "x": 0,
        "z": 4
    },
    "name": "sticker",
    "text": "Place two 1x6 plates across the top to support the 2x2 battery anchor tile."
},
{
    "step": 6,
    "position": {
        "x": 0,
        "z": 5.4
    },
    "rotation": {
        "yaw": 90
    },
    "name": "battery",
    "text": "Attach a fully charged battery."
},
{
    "step": 7,
    "position": {
        "x": -3.75,
        "y": -3.665,
        "z": -1
    },
    "rotation": {
        "yaw": 60
    },
    "name": "motor",
    "channel": 1,
    "ccw": true,
    "text": "Install a CCW motor (black and white wires) in the front left position with a CCW propeller and connect it to channel 1 on the flightboard. Be sure to line up the white dots on the connector."
},
{
    "step": 7,
    "position": {
        "x": -3.75,
        "y": -3.665,
        "z": -1
    },
    "rotation": {
        "yaw": 60
    },
    "name": "prop_ccw"
},
{
    "step": 8,
    "position": {
        "x": 3.75,
        "y": -3.665,
        "z": -1
    },
    "rotation": {
        "yaw": -240
    },
    "name": "motor",
    "channel": 7,
    "ccw": false,
    "text": "The rear left motor should connect to channel 7. Use a CW motor (red and blue wires) and a CW propeller."
},
{
    "step": 8,
    "position": {
        "x": 3.75,
        "y": -3.665,
        "z": -1
    },
    "rotation": {
        "yaw": -240
    },
    "name": "prop_cw"
},
{
    "step": 9,
    "position": {
        "x": 3.75,
        "y": 3.665,
        "z": -1
    },
    "rotation": {
        "yaw": 240
    },
    "name": "motor",
    "channel": 6,
    "ccw": true,
    "text": "Install a CCW motor and a CCW propeller on channel 6 in the rear right position."
},
{
    "step": 9,
    "position": {
        "x": 3.75,
        "y": 3.665,
        "z": -1
    },
    "rotation": {
        "yaw": 240
    },
    "name": "prop_ccw"
},
{
    "step": 10,
    "position": {
        "x": -3.75,
        "y": 3.665,
        "z": -1
    },
    "rotation": {
        "yaw": -60
    },
    "name": "motor",
    "channel": 0,
    "ccw": false,
    "text": "The final motor should be installed on channel 0."
},
{
    "step": 10,
    "position": {
        "x": -3.75,
        "y": 3.665,
        "z": -1
    },
    "rotation": {
        "yaw": -60
    },
    "name": "prop_cw"
},
{
    "step": 11,
    "position": {
        "x": -3,
        "z": 3
    },
    "rotation": {
        "yaw": 90
    },
    "name": "windshield"
},
{
    "step": 11,
    "position": {
        "x": 2.5,
        "z": 4
    },
    "rotation": {
        "yaw": 90
    },
    "name": "fin",
    "text": "Attach the windshield at the front and the tail fin at the rear."
},
{
    "step": 12,
    "text": "The X Wing design is a lightweight and nimble quadcopter."
}];

angular.module('flybrixAssemblyViewer').constant('flatXWing', JSON.stringify(flatXWing));

}());
