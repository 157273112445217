(function() {
    'use strict';

    designFactory.$inject = ['$q', 'fbaColors', 'fbaPrefabs'];

    angular.module('flybrixAssemblyViewer').factory('fbaDesign', designFactory);

    function designFactory($q, fbaColors, fbaPrefabs) {
        return handleDesign;

        function isHidden(part, step) {
            return part.step > step || part.endstep < step;
        }

        function handleDesign(design, highlight) {
            var elements = new THREE.Group();
            if (!Array.isArray(design)) {
                return elements;
            }

            var hlMaterial = new THREE.LineBasicMaterial({
                color: highlight.color || 0,
                linewidth: highlight.weight || 0,
            });

            var allParts = design.slice();

            var pcb = design.find(function(v) {
                return v.name === 'pcb';
            });

            if (isHidden(pcb, highlight.step)) {
                pcb = undefined;
            }

            for (var idx = 0; idx < allParts.length; ++idx) {
                var part = allParts[idx];
                if (isHidden(part, highlight.step)) {
                    continue;
                }
                var highlighted = highlight.step === part.step;
                handlePart(part, highlighted, hlMaterial, pcb).then(function(vs) {
                    vs.forEach(function(v) {
                        elements.add(v);
                    })
                });
            }

            return elements;
        }

        // workaround for webGL ANGLE forcing line thickness to one (https://stackoverflow.com/questions/15316127)
        function cylinderMesh(pointX, pointY, radius, color) {
            var direction = new THREE.Vector3().subVectors(pointY, pointX);
            var orientation = new THREE.Matrix4();
            orientation.lookAt(pointX, pointY, new THREE.Object3D().up);
            orientation.multiply(new THREE.Matrix4().set(1, 0, 0, 0,
                                                        0, 0, 1, 0,
                                                        0, -1, 0, 0,
                                                        0, 0, 0, 1));
            var edgeGeometry = new THREE.CylinderGeometry(radius, radius, direction.length(), 8, 1);
            var edge = new THREE.Mesh(edgeGeometry, new THREE.MeshBasicMaterial( { color: color } ));
            edge.userData.cleanup = function() {
                edge.geometry.dispose();
                edge.material.dispose();
            };
            edge.applyMatrix(orientation);
            // position based on midpoints - there may be a better solution than this
            edge.position.x = (pointY.x + pointX.x) / 2;
            edge.position.y = (pointY.y + pointX.y) / 2;
            edge.position.z = (pointY.z + pointX.z) / 2;
            return edge;
        }
        
        function handlePart(part, highlighted, hlMaterial, pcb) {
            var q;
            var material;
            if (highlighted) {
                material = new THREE.MeshPhongMaterial({
                    color: fbaColors.getPaint(part),
                    polygonOffset: true,
                    polygonOffsetFactor: 1,
                    polygonOffsetUnits: 1,
                });
            } else {
                material = new THREE.MeshPhongMaterial({color: fbaColors.getPaint(part)});
                hlMaterial = null;
            }
            if (part.plate) {
                q = fbaPrefabs.loadPlate(
                    material,
                    Number(part.width) || 1,
                    Number(part.length) || 1,
                    hlMaterial);
            } else if (part.name) {
                q = fbaPrefabs.loadPrefab(material, part.name, hlMaterial);
            } else {
                return $q.resolve([]);
            }

            var components = [];
            
            var yaw_rotation_deg = (Number((part.rotation || {}).yaw) || 0);
            var pos = part.position || {};
            var position = new THREE.Vector3(
                (Number(pos.x) || 0),
                (Number(pos.y) || 0),
                (Number(pos.z) || 0));
                    
            if (part.offset_yaw_rotation) {
                var originX = part.offset_yaw_rotation.x;
                var originY = part.offset_yaw_rotation.y;
                var pointX = 0;
                var pointY = 0;
                var angle = part.offset_yaw_rotation.angle_deg * Math.PI/180;
                var rotatedPointX = Math.cos(angle) * (pointX-originX) - Math.sin(angle) * (pointY-originY) + originX;
                var rotatedPointY = Math.sin(angle) * (pointX-originX) + Math.cos(angle) * (pointY-originY) + originY;
                //console.log( pointX, pointY, "rotates", part.offset_yaw_rotation.angle_deg,  "deg around origin to", rotatedPointX, rotatedPointY);
                var pos = part.position || {};
                var position = new THREE.Vector3(
                    (Number(pos.x) || 0),
                    (Number(pos.y) || 0),
                    (Number(pos.z) || 0));
                position.x -= rotatedPointX;
                position.y -= rotatedPointY;

                yaw_rotation_deg += part.offset_yaw_rotation.angle_deg;
            }
            
            var scaled_position = new THREE.Vector3(
                                (position.x) * 8,
                                (position.y) * 8,
                                (position.z) * 3.2);
                                
            components.push(q.then(function(v) {
                v.position.copy(scaled_position);
                v.rotateZ(yaw_rotation_deg * Math.PI / 180);
                return v;
            }));
            
            if (pcb && ('channel' in part)) {
                var motor_base = fbaPrefabs.getMotorBase(part.name);
                var channel_location = fbaPrefabs.getChannelLocation(part.channel).position;
                var color1 = 0xffffff;
                var color2 = 0x000000;
                if (!part.ccw) {
                    color1 = 0xff0000;
                    color2 = 0x0000ff;
                }
                if (motor_base) {
                    motor_base.z += 15.5;
                    motor_base.applyAxisAngle(new THREE.Vector3(0, 0, 1), yaw_rotation_deg * Math.PI / 180);
                    motor_base.add(scaled_position);
                    if (pcb.position) {
                        var pcb_pos = new THREE.Vector3(
                                (Number(pcb.position.x) || 0) * 8,
                                (Number(pcb.position.y) || 0) * 8,
                                (Number(pcb.position.z) || 0) * 3.2);
                        var pcb_yaw_rotation = (Number((pcb.rotation || {}).yaw) || 0) * Math.PI / 180;
                        channel_location.z -= 0.2;
                        channel_location.applyAxisAngle(new THREE.Vector3(0, 0, 1), pcb_yaw_rotation);
                        channel_location.add(pcb_pos);

                        var line_direction = channel_location.clone().sub(motor_base);
                        var shift_direction = line_direction.cross(new THREE.Vector3(0, 0, 1)).normalize();
                        var v0 = motor_base.clone().addScaledVector(shift_direction, 0.5);
                        var v1 = channel_location.clone().addScaledVector(shift_direction, 0.5);
                        components.push($q.resolve(cylinderMesh(v0, v1, 0.8, color1)));
                        v0 = motor_base.clone().addScaledVector(shift_direction, -0.5);
                        v1 = channel_location.clone().addScaledVector(shift_direction, -0.5);
                        components.push($q.resolve(cylinderMesh(v0, v1, 0.8, color2)));
                    }
                    else{
                        console.log("no pcb.position?!", pcb.position);
                    }
                }
                else{
                    console.log("no motor_base?!", motor_base);
                }
            }
            return $q.all(components);
        }
    }

}());
