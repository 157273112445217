(function() {
    'use strict';
    
var flat8 =
[{
    "step": 0,
    "position": {
        "z": -6
    },
    "name": "frame_4x4"
},
{
    "step": 0,
    "position": {
        "y": 1.5,
        "z": -5
    },
    "name": "plate_1x4_mid_flat"
},
{
    "step": 0,
    "position": {
        "y": -1.5,
        "z": -5
    },
    "name": "plate_1x4_mid_flat",
    "text": "Start by attaching the two 1x4 plates with flat middles to the 4x4 open frame plate."
},
{
    "step": 1,
    "position": {
        "x": -0.88584,
        "y": -1.85044,
        "z": -4
    },
    "rotation": {
        "yaw": 105.291
    },
    "name": "joint_a"
},
{
    "step": 1,
    "position": {
        "x": 0.88584,
        "y": -1.85044,
        "z": -4
    },
    "rotation": {
        "yaw": -105.291
    },
    "name": "joint_b"
},
{
    "step": 1,
    "position": {
        "x": -0.88584,
        "y": 1.85044,
        "z": -4
    },
    "rotation": {
        "yaw": 74.709
    },
    "name": "joint_b"
},
{
    "step": 1,
    "position": {
        "x": 0.88584,
        "y": 1.85044,
        "z": -4
    },
    "rotation": {
        "yaw": -74.709
    },
    "name": "joint_a"
},
{
    "step": 1,
    "position": {
        "x": -0.88584,
        "y": -1.85044,
        "z": -4
    },
    "rotation": {
        "yaw": 154.7508
    },
    "name": "joint_b"
},
{
    "step": 1,
    "position": {
        "x": 0.88584,
        "y": -1.85044,
        "z": -4
    },
    "rotation": {
        "yaw": -154.7508
    },
    "name": "joint_a"
},
{
    "step": 1,
    "position": {
        "x": -0.88584,
        "y": 1.85044,
        "z": -4
    },
    "rotation": {
        "yaw": 25.2492
    },
    "name": "joint_a"
},
{
    "step": 1,
    "position": {
        "x": 0.88584,
        "y": 1.85044,
        "z": -4
    },
    "rotation": {
        "yaw": -25.2492
    },
    "name": "joint_b",
    "text": "Use the four hinge plate pieces to create an octagon pattern."
},
{
    "step": 2,
    "position": {
        "x": 1.28672,
        "y": 3.87264,
        "z": -3
    },
    "rotation": {
        "yaw": -25.2492
    },
    "plate": "true",
    "width": 4
},
{
    "step": 3,
    "position": {
        "x": 4.8761,
        "y": 2.42302,
        "z": -3
    },
    "rotation": {
        "yaw": -74.709
    },
    "plate": "true",
    "width": 8
},
{
    "step": 2,
    "position": {
        "x": -1.28672,
        "y": 3.87264,
        "z": -3
    },
    "rotation": {
        "yaw": 25.2492
    },
    "plate": "true",
    "width": 4
},
{
    "step": 3,
    "position": {
        "x": -4.8761,
        "y": 2.42302,
        "z": -3
    },
    "rotation": {
        "yaw": 74.709
    },
    "plate": "true",
    "width": 8
},
{
    "step": 2,
    "position": {
        "x": 1.28672,
        "y": -3.87264,
        "z": -3
    },
    "rotation": {
        "yaw": -154.7508
    },
    "plate": "true",
    "width": 4
},
{
    "step": 3,
    "position": {
        "x": 4.8761,
        "y": -2.42302,
        "z": -3
    },
    "rotation": {
        "yaw": -105.291
    },
    "plate": "true",
    "width": 8
},
{
    "step": 2,
    "position": {
        "x": -1.28672,
        "y": -3.87264,
        "z": -3
    },
    "rotation": {
        "yaw": 154.7508
    },
    "plate": "true",
    "width": 4,
    "text": "Connect four 1x4 plate pieces to sides of the hinges that touch at their edges."
},
{
    "step": 3,
    "position": {
        "x": -4.8761,
        "y": -2.42302,
        "z": -3
    },
    "rotation": {
        "yaw": 105.291
    },
    "plate": "true",
    "width": 8,
    "text": "Connect four 1x8 plates to the other four sides of the hinge pieces."
},
{
    "step": 4,
    "position": {
        "x": -1.5,
        "z": -2
    },
    "plate": "true",
    "width": 6
},
{
    "step": 4,
    "position": {
        "x": 1.5,
        "z": -2
    },
    "plate": "true",
    "width": 6,
    "text": "Connect two 1x6 plate pieces across the ends of the 1x8 plates."
},
{
    "step": 5,
    "position": {
        "x": 3.4292,
        "y": 2.02744,
        "z": -2
    },
    "name": "stud"
},
{
    "step": 5,
    "position": {
        "x": -3.4292,
        "y": 2.02744,
        "z": -2
    },
    "name": "stud"
},
{
    "step": 5,
    "position": {
        "x": 3.4292,
        "y": -2.02744,
        "z": -2
    },
    "name": "stud"
},
{
    "step": 5,
    "position": {
        "x": -3.4292,
        "y": -2.02744,
        "z": -2
    },
    "name": "stud",
    "text": "Place a 1x1 round piece at the third knob position on each 1x8 plate."
},
{
    "step": 6,
    "position": {
        "x": 2.56639,
        "y": 6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -115.2492
    },
    "name": "motor_arm"
},
{
    "step": 6,
    "position": {
        "x": 6.8053,
        "y": 2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -164.709
    },
    "name": "motor_arm"
},
{
    "step": 6,
    "position": {
        "x": -2.56639,
        "y": 6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -64.7508
    },
    "name": "motor_arm"
},
{
    "step": 6,
    "position": {
        "x": -6.8053,
        "y": 2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -15.291
    },
    "name": "motor_arm"
},
{
    "step": 6,
    "position": {
        "x": 2.56639,
        "y": -6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -244.7508
    },
    "name": "motor_arm"
},
{
    "step": 6,
    "position": {
        "x": 6.8053,
        "y": -2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -195.291
    },
    "name": "motor_arm"
},
{
    "step": 6,
    "position": {
        "x": -2.56639,
        "y": -6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": 64.7508
    },
    "name": "motor_arm"
},
{
    "step": 6,
    "position": {
        "x": -6.8053,
        "y": -2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": 15.291
    },
    "name": "motor_arm",
    "text": "Connect eight straight motor arms. The motor arms will attach to five knobs on each 1x8 plate and two knobs on each of the 1x4 plates."
},
{
    "step": 7,
    "position": {
        "x": 2.4646,
        "y": 3.17615,
        "z": -1
    },
    "rotation": {
        "yaw": -50
    },
    "plate": true,
    "length": 4
},
{
    "step": 7,
    "position": {
        "x": -2.4646,
        "y": 3.17615,
        "z": -1
    },
    "rotation": {
        "yaw": 50
    },
    "plate": true,
    "length": 4
},
{
    "step": 7,
    "position": {
        "x": 2.4646,
        "y": -3.17615,
        "z": -1
    },
    "rotation": {
        "yaw": -130
    },
    "plate": true,
    "length": 4
},
{
    "step": 7,
    "position": {
        "x": -2.4646,
        "y": -3.17615,
        "z": -1
    },
    "rotation": {
        "yaw": 130
    },
    "plate": true,
    "length": 4,
    "text": "Use a 1x4 plate to fix the angle between each pair of motor arms."
},
{
    "step": 8,
    "position": {
        "x": 1.5,
        "y": 2.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 8,
    "position": {
        "x": -1.5,
        "y": 2.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 8,
    "position": {
        "x": 1.5,
        "y": -2.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 8,
    "position": {
        "x": -1.5,
        "y": -2.5,
        "z": -1
    },
    "name": "stud",
    "text": "Use four 1x1 round pieces as risers for the flightboard."
},
{
    "step": 9,
    "position": {
        
    },
    "name": "pcb",
    "position": {
        "x": 0,
        "y": 0,
        "z": 0
    },
    "text": "Place the flightboard on the four 1x1 round pieces."
},
{
    "step": 10,
    "position": {
        "x": 3.4292,
        "y": 2.02744
    },
    "name": "stud"
},
{
    "step": 10,
    "position": {
        "x": -3.4292,
        "y": 2.02744
    },
    "name": "stud"
},
{
    "step": 10,
    "position": {
        "x": 3.4292,
        "y": -2.02744
    },
    "name": "stud"
},
{
    "step": 10,
    "position": {
        "x": -3.4292,
        "y": -2.02744
    },
    "name": "stud",
    "text": "Attach a 1x1 round piece at one end of each 1x4 plate."
},
{
    "step": 11,
    "position": {
        "x": 2.4646,
        "y": 2.26372,
        "z": 1
    },
    "rotation": {
        "yaw": -13.764
    },
    "plate": true,
    "length": 3
},
{
    "step": 11,
    "position": {
        "x": -2.4646,
        "y": 2.26372,
        "z": 1
    },
    "rotation": {
        "yaw": 13.764
    },
    "plate": true,
    "length": 3
},
{
    "step": 11,
    "position": {
        "x": 2.4646,
        "y": -2.26372,
        "z": 1
    },
    "rotation": {
        "yaw": -166.236
    },
    "plate": true,
    "length": 3
},
{
    "step": 11,
    "position": {
        "x": -2.4646,
        "y": -2.26372,
        "z": 1
    },
    "rotation": {
        "yaw": 166.236
    },
    "plate": true,
    "length": 3,
    "text": "Use four 1x3 plates to connect each pair of motor arms to the flightboard."
},
{
    "step": 12,
    "position": {
        "x": 3.4292,
        "y": 0.5,
        "z": 2
    },
    "plate": true,
    "width": 6
},
{
    "step": 12,
    "position": {
        "x": -3.4292,
        "y": 0.5,
        "z": 2
    },
    "plate": true,
    "width": 6,
    "text": "Connect the ends of the 1x3 plate pieces together with two 1x6 plates."
},
{
    "step": 13,
    "position": {
        "y": 4.32487
    },
    "plate": true,
    "length": 4
},
{
    "step": 13,
    "position": {
        "y": -4.32487
    },
    "plate": true,
    "length": 4,
    "text": "Attach a 1x4 plate at the front and back."
},
{
    "step": 14,
    "text": "Next we will place and connect eight motors, starting at the rear left position and proceeding in a clockwise direction. Be sure to match CW propellers with CW motors (red and blue wires) and CCW propellers with CCW motors (black and white wires) and be careful to align the white marks on each motor connector with the corresponding white marks on the flight board."
},
{
    "step": 15,
    "position": {
        "x": 2.56639,
        "y": 6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -115.2492
    },
    "name": "motor",
    "channel": 7,
    "ccw": false
},
{
    "step": 15,
    "position": {
        "x": 2.56639,
        "y": 6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -115.2492
    },
    "name": "prop_cw",
    "text": "Connect a CW motor and propeller to channel 7."
},
{
    "step": 16,
    "position": {
        "x": 6.8053,
        "y": 2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -164.709
    },
    "name": "motor",
    "channel": 5,
    "ccw": true
},
{
    "step": 16,
    "position": {
        "x": 6.8053,
        "y": 2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -164.709
    },
    "name": "prop_ccw",
    "text": "Connect a CCW motor and propeller to channel 5."
},
{
    "step": 17,
    "position": {
        "x": 6.8053,
        "y": -2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -195.291
    },
    "name": "motor",
    "channel": 3,
    "ccw": false
},
{
    "step": 17,
    "position": {
        "x": 6.8053,
        "y": -2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -195.291
    },
    "name": "prop_cw",
    "text": "Connect a CW motor and propeller to channel 3."
},
{
    "step": 18,
    "position": {
        "x": 2.56639,
        "y": -6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -244.7508
    },
    "name": "motor",
    "channel": 1,
    "ccw": true
},
{
    "step": 18,
    "position": {
        "x": 2.56639,
        "y": -6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -244.7508
    },
    "name": "prop_ccw",
    "text": "Connect a CCW motor and propeller to channel 1."
},
{
    "step": 19,
    "position": {
        "x": -2.56639,
        "y": -6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": 64.7508
    },
    "name": "motor",
    "channel": 0,
    "ccw": false
},
{
    "step": 19,
    "position": {
        "x": -2.56639,
        "y": -6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": 64.7508
    },
    "name": "prop_cw",
    "text": "Connect a CW motor and propeller to channel 0."
},
{
    "step": 20,
    "position": {
        "x": -6.8053,
        "y": -2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": 15.291
    },
    "name": "motor",
    "channel": 2,
    "ccw": true
},
{
    "step": 20,
    "position": {
        "x": -6.8053,
        "y": -2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": 15.291
    },
    "name": "prop_ccw",
    "text": "Connect a CCW motor and propeller to channel 2."
},
{
    "step": 21,
    "position": {
        "x": -6.8053,
        "y": 2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -15.291
    },
    "name": "motor",
    "channel": 4,
    "ccw": false
},
{
    "step": 21,
    "position": {
        "x": -6.8053,
        "y": 2.95046,
        "z": -2
    },
    "rotation": {
        "yaw": -15.291
    },
    "name": "prop_cw",
    "text": "Connect a CW motor and propeller to channel 4."
},
{
    "step": 22,
    "position": {
        "x": -2.56639,
        "y": 6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -64.7508
    },
    "name": "motor",
    "channel": 6,
    "ccw": true
},
{
    "step": 22,
    "position": {
        "x": -2.56639,
        "y": 6.58602,
        "z": -2
    },
    "rotation": {
        "yaw": -64.7508
    },
    "name": "prop_ccw",
    "text": "Last one! Connect a CCW motor and propeller to channel 6."
},
{
    "step": 23,
    "position": {
        "y": 2.5,
        "z": 2
    },
    "plate": true,
    "length": 4
},
{
    "step": 23,
    "position": {
        "y": -3,
        "z": 2
    },
    "plate": true,
    "width": 2,
    "length": 4,
    "text": "Use one 1x4 plate and one 2x4 plate to add mounting points for the battery and windshield."
},
{
    "step": 24,
    "position": {
        "y": 2,
        "z": 3
    },
    "name": "sticker",
    "text": "Attach the 2x2 battery anchor tile to the 1x4 plate."
},
{
    "step": 25,
    "position": {
        "y": 2,
        "z": 5.5
    },
    "name": "battery",
    "text": "Attach a fully charged battery."
},
{
    "step": 26,
    "position": {
        "y": 4.32487,
        "z": 1
    },
    "rotation": {
        "yaw": 180
    },
    "name": "fin"
},
{
    "step": 26,
    "position": {
        "y": -4,
        "z": 3
    },
    "rotation": {
        "yaw": 180
    },
    "name": "windshield",
    "text": "Add the windshield and tail fin as finishing touches."
},
{
    "step": 27,
    "text": "The Flat Eight octocopter has the most lift of all our suggested designs."
}];

angular.module('flybrixAssemblyViewer').constant('flat8', JSON.stringify(flat8));

}());
