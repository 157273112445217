(function() {
    'use strict';
    
var dualSided =
[{
    "step": 0,
    "position": {
        "z": -6
    },
    "plate": true,
    "width": 2,
    "length": 4
},
{
    "step": 0,
    "position": {
        "z": -5
    },
    "name": "sticker",
    "text": "Start by attaching the 2x2 battery anchor tile to a 2x4 plate."
},
{
    "step": 1,
    "position": {
        "x": -1.5,
        "z": -5
    },
    "plate": true,
    "width": 6,
    "length": 1
},
{
    "step": 1,
    "position": {
        "x": 1.5,
        "z": -5
    },
    "plate": true,
    "width": 6,
    "length": 1,
    "text": "Place a 1x6 plate on each side of the tile."
},
{
    "step": 2,
    "position": {
        "y": -2.5,
        "z": -4
    },
    "plate": true,
    "width": 1,
    "length": 4
},
{
    "step": 2,
    "position": {
        "y": 2.5,
        "z": -4
    },
    "plate": true,
    "width": 1,
    "length": 4
},
{
    "step": 2,
    "position": {
        "x": 1.5,
        "y": -2.5,
        "z": -3
    },
    "name": "stud"
},
{
    "step": 2,
    "position": {
        "x": -1.5,
        "y": -2.5,
        "z": -3
    },
    "name": "stud"
},
{
    "step": 2,
    "position": {
        "x": 1.5,
        "y": 2.5,
        "z": -3
    },
    "name": "stud"
},
{
    "step": 2,
    "position": {
        "x": -1.5,
        "y": 2.5,
        "z": -3
    },
    "name": "stud",
    "text": "Use two 1x4 plates and four 1x1 round pieces to start making a space for the battery."
},
{
    "step": 3,
    "position": {
        "y": -2.5,
        "z": -2
    },
    "name": "motor_arm_double"
},
{
    "step": 3,
    "position": {
        "y": 2.5,
        "z": -2
    },
    "rotation": {
        "yaw": 180
    },
    "name": "motor_arm_double",
    "text": "Install a dual motor arm across each pair of 1x1 round pieces."
},
{
    "step": 4,
    "position": {
        "y": 0,
        "z": -2.5
    },
    "rotation": {
        "yaw": 90
    },
    "name": "battery"
},
{
    "step": 4,
    "position": {
        "x": 1.5,
        "y": -2.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 4,
    "position": {
        "x": -1.5,
        "y": -2.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 4,
    "position": {
        "x": 1.5,
        "y": 2.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 4,
    "position": {
        "x": -1.5,
        "y": 2.5,
        "z": -1
    },
    "name": "stud",
    "text": "Attach a fully charged battery to the anchor and place four more 1x1 round pieces to provide clearance over the top of it."
},
{
    "step": 5,
    "name": "pcb",
    "position": {
        "x": 0,
        "y": 0,
        "z": 0
    },
    "text": "The flightboard attaches to the four 1x1 round pieces."
},
{
    "step": 6,
    "position": {
        "x": -4.2,
        "y": -4.36,
        "z": -1
    },
    "rotation": {
        "yaw": 60
    },
    "name": "motor",
    "channel": 0,
    "ccw": false,
    "text": "Install a CW motor (red and blue wires) in the front right position with a CW propeller and connect it to channel 0 on the flightboard. Be sure to line up the white dots on the connector."
},
{
    "step": 6,
    "position": {
        "x": -4.2,
        "y": -4.36,
        "z": -1
    },
    "rotation": {
        "yaw": 60
    },
    "name": "prop_cw"
},
{
    "step": 7,
    "position": {
        "x": 4.2,
        "y": -4.36,
        "z": -1
    },
    "rotation": {
        "yaw": 120
    },
    "name": "motor",
    "channel": 1,
    "ccw": true,
    "text": "Install a CCW motor (black and white wires) in the front left position with a CCW propeller and connect it to channel 1."
},
{
    "step": 7,
    "position": {
        "x": 4.2,
        "y": -4.36,
        "z": -1
    },
    "rotation": {
        "yaw": 120
    },
    "name": "prop_ccw"
},
{
    "step": 8,
    "position": {
        "x": -4.2,
        "y": 4.36,
        "z": -1
    },
    "rotation": {
        "yaw": -60
    },
    "name": "motor",
    "channel": 6,
    "ccw": true,
    "text": "Install a CCW motor and a CCW propeller at the rear right position. Connect the motor to channel 6 on the flightboard."
},
{
    "step": 8,
    "position": {
        "x": -4.2,
        "y": 4.36,
        "z": -1
    },
    "rotation": {
        "yaw": -60
    },
    "name": "prop_ccw"
},
{
    "step": 9,
    "position": {
        "x": 4.2,
        "y": 4.36,
        "z": -1
    },
    "rotation": {
        "yaw": -120
    },
    "name": "motor",
    "channel": 7,
    "ccw": false,
    "text": "The rear left motor and propeller should both be the CW type. Connect the motor to channel 7."
},
{
    "step": 9,
    "position": {
        "x": 4.2,
        "y": 4.36,
        "z": -1
    },
    "rotation": {
        "yaw": -120
    },
    "name": "prop_cw"
},
{
    "step": 10,
    "position": {
        "y": 2.5,
        "z": 1
    },
    "plate": true,
    "width": 1,
    "length": 4
},
{
    "step": 10,
    "position": {
        "y": -3,
        "z": 1
    },
    "plate": true,
    "width": 2,
    "length": 4
},
{
    "step": 10,
    "position": {
        "y": 2.5,
        "z": 2
    },
    "rotation": {
        "yaw": -180
    },
    "name": "fin"
},
{
    "step": 10,
    "position": {
        "y": -3,
        "z": 2
    },
    "rotation": {
        "yaw": 180
    },
    "name": "windshield",
    "text": "Use a 2x4 plate and a 1x4 plate to add the windshield and tail fin as finishing touches."
},
{
    "step": 11,
    "text": "The Dual Sided design balances weight and stability."
}];

angular.module('flybrixAssemblyViewer').constant('dualSided', JSON.stringify(dualSided));

}());
