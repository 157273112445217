(function() {
    'use strict';
    
var flat6 =
[{
    "step": 0,
    "position": {
        "x": 1.324,
        "y": 3.8,
        "z": -4
    },
    "rotation": {
        "yaw": -28
    },
    "name": "plate_1x4_mid_flat"
},
{
    "step": 0,
    "position": {
        "x": 1.324,
        "y": -3.8,
        "z": -4
    },
    "rotation": {
        "yaw": 28
    },
    "name": "plate_1x4_mid_flat"
},
{
    "step": 0,
    "position": {
        "x": 2.648,
        "y": 3.096,
        "z": -3
    },
    "name": "stud"
},
{
    "step": 0,
    "position": {
        "x": 2.648,
        "y": -3.096,
        "z": -3
    },
    "name": "stud",
    "text": "Start by placing two 1x1 round pieces on two 1x4 tiles with flat middles."
},
{
    "step": 1,
    "position": {
        "x": -2.648,
        "y": 3.096,
        "z": -4
    },
    "name": "stud"
},
{
    "step": 1,
    "position": {
        "x": -2.648,
        "y": -3.096,
        "z": -4
    },
    "name": "stud",
    "text": "Place two 1x1 round pieces next to the previous assembly."
},
{
    "step": 2,
    "position": {
        "x": -1.324,
        "y": 3.8,
        "z": -3
    },
    "rotation": {
        "yaw": 28
    },
    "plate": "true",
    "length": 4
},
{
    "step": 2,
    "position": {
        "x": -1.324,
        "y": -3.8,
        "z": -3
    },
    "rotation": {
        "yaw": -28
    },
    "plate": "true",
    "length": 4,
    "text": "Use two 1x4 plates to attach the 1x1 round pieces to the 1x4 plates with flat middles."
},
{
    "step": 3,
    "position": {
        "x": -0.5,
        "y": 1.5,
        "z": -3
    },
    "plate": "true",
    "length": 8
},
{
    "step": 3,
    "position": {
        "x": -0.5,
        "y": -1.5,
        "z": -3
    },
    "plate": "true",
    "length": 8
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "y": 2,
        "z": -2
    },
    "rotation": {
        "yaw": -90
    },
    "name": "joint_a"
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "y": 2,
        "z": -2
    },
    "rotation": {
        "yaw": -65
    },
    "name": "joint_b"
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "y": -2,
        "z": -2
    },
    "rotation": {
        "yaw": -115
    },
    "name": "joint_a"
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "y": -2,
        "z": -2
    },
    "rotation": {
        "yaw": -90
    },
    "name": "joint_b"
},
{
    "step": 3,
    "position": {
        "x": -1.5,
        "y": 2,
        "z": -2
    },
    "rotation": {
        "yaw": 90
    },
    "name": "joint_b"
},
{
    "step": 3,
    "position": {
        "x": -1.5,
        "y": 2,
        "z": -2
    },
    "rotation": {
        "yaw": 65
    },
    "name": "joint_a"
},
{
    "step": 3,
    "position": {
        "x": -1.5,
        "y": -2,
        "z": -2
    },
    "rotation": {
        "yaw": 115
    },
    "name": "joint_b"
},
{
    "step": 3,
    "position": {
        "x": -1.5,
        "y": -2,
        "z": -2
    },
    "rotation": {
        "yaw": 90
    },
    "name": "joint_a",
    "text": "Attach two 1x8 pieces using 1x4 hinges."
},
{
    "step": 4,
    "position": {
        "z": -2
    },
    "plate": "true",
    "width": 12
},
{
    "step": 4,
    "position": {
        "x": 2,
        "y": 1.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 4,
    "position": {
        "x": -3,
        "y": 1.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 4,
    "position": {
        "x": 2,
        "y": -1.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 4,
    "position": {
        "x": -3,
        "y": -1.5,
        "z": -1
    },
    "name": "stud",
    "text": "Connect the two sides with a 1x12 plate and attach four 1x1 round pieces."
},
{
    "step": 5,
    "rotation": {
        "yaw": -90
    },
    "name": "pcb",
    "position": {
        "x": -0.5,
        "y": 0,
        "z": 0
    },
    "text": "Attach the flightboard to the 1x1 round pieces."
},
{
    "step": 6,
    "position": {
        "x": 3.5,
        "z": -1
    },
    "plate": "true",
    "length": 2,
    "width": 4
},
{
    "step": 6,
    "position": {
        "x": -4.5,
        "z": -2
    },
    "plate": true,
    "width": 4,
    "length": 2,
    "text": "Attach 2x4 plates at the front and back of the drone."
},
{
    "step": 7,
    "position": {
        "y": -7,
        "z": -1
    },
    "rotation": {
        "yaw": 90
    },
    "name": "motor_arm"
},
{
    "step": 7,
    "position": {
        "y": 7,
        "z": -1
    },
    "rotation": {
        "yaw": -90
    },
    "name": "motor_arm"
},
{
    "step": 7,
    "position": {
        "x": 4.01,
        "y": 3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 205
    },
    "name": "motor_arm"
},
{
    "step": 7,
    "position": {
        "x": 4.01,
        "y": -3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 155
    },
    "name": "motor_arm"
},
{
    "step": 7,
    "position": {
        "x": -4.01,
        "y": 3.72,
        "z": -1
    },
    "rotation": {
        "yaw": -25
    },
    "name": "motor_arm"
},
{
    "step": 7,
    "position": {
        "x": -4.01,
        "y": -3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 25
    },
    "name": "motor_arm",
    "text": "Attach 6 straight motor arms in a hexagon pattern as shown. Each motor arm will connect to two knobs on the piece below it."
},
{
    "step": 8,
    "text": "Next we will place and connect six motors, starting at the rear left position and proceeding in a clockwise direction. Be sure to match CW propellers with CW motors (red and blue wires) and CCW propellers with CCW motors (black and white wires) and be careful to align the white marks on each motor connector with the corresponding white marks on the flight board."
},
{
    "step": 10,
    "position": {
        "y": -7,
        "z": -1
    },
    "rotation": {
        "yaw": 90
    },
    "name": "prop_cw"
},
{
    "step": 10,
    "position": {
        "y": -7,
        "z": -1
    },
    "rotation": {
        "yaw": 90
    },
    "name": "motor",
    "channel": 3,
    "ccw": false,
    "text": "Connect a CW motor and propeller to channel 3."
},
{
    "step": 13,
    "position": {
        "y": 7,
        "z": -1
    },
    "rotation": {
        "yaw": -90
    },
    "name": "prop_ccw"
},
{
    "step": 13,
    "position": {
        "y": 7,
        "z": -1
    },
    "rotation": {
        "yaw": -90
    },
    "name": "motor",
    "channel": 2,
    "ccw": true,
    "text": "Connect a CCW motor and propeller to channel 2."
},
{
    "step": 14,
    "position": {
        "x": 4.01,
        "y": 3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 205
    },
    "name": "prop_cw"
},
{
    "step": 14,
    "position": {
        "x": 4.01,
        "y": 3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 205
    },
    "name": "motor",
    "channel": 6,
    "ccw": false,
    "text": "Connect a CW motor and propeller to channel 6."
},
{
    "step": 9,
    "position": {
        "x": 4.01,
        "y": -3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 155
    },
    "name": "prop_ccw"
},
{
    "step": 9,
    "position": {
        "x": 4.01,
        "y": -3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 155
    },
    "name": "motor",
    "channel": 7,
    "ccw": true,
    "text": "Connect a CCW motor and propeller to channel 7."
},
{
    "step": 12,
    "position": {
        "x": -4.01,
        "y": 3.72,
        "z": -1
    },
    "rotation": {
        "yaw": -25
    },
    "name": "prop_cw"
},
{
    "step": 12,
    "position": {
        "x": -4.01,
        "y": 3.72,
        "z": -1
    },
    "rotation": {
        "yaw": -25
    },
    "name": "motor",
    "channel": 0,
    "ccw": false,
    "text": "Connect a CW motor and propeller to channel 0."
},
{
    "step": 11,
    "position": {
        "x": -4.01,
        "y": -3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 25
    },
    "name": "prop_ccw"
},
{
    "step": 11,
    "position": {
        "x": -4.01,
        "y": -3.72,
        "z": -1
    },
    "rotation": {
        "yaw": 25
    },
    "name": "motor",
    "channel": 1,
    "ccw": true,
    "text": "Connect a CCW motor and propeller to channel 1."
},
{
    "step": 15,
    "position": {
        "x": 2,
        "y": 1.5,
        "z": 1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 15,
    "position": {
        "x": 2,
        "y": -1.5,
        "z": 1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 15,
    "position": {
        "x": -3,
        "y": 1.5,
        "z": 1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 15,
    "position": {
        "x": -3,
        "y": -1.5,
        "z": 1
    },
    "name": "stud",
    "part_number": "LEGO-4073",
    "text": "Attach four 1x1 round pieces to the knobs on the flightboard."
},
{
    "step": 16,
    "position": {
        "x": 2,
        "z": 2
    },
    "plate": true,
    "width": 4
},
{
    "step": 16,
    "position": {
        "x": -3,
        "z": 2
    },
    "plate": true,
    "width": 4,
    "text": "Place two 1x4 plates across the 1x1 pieces."
},
{
    "step": 17,
    "position": {
        "x": -0.5,
        "y": 0.5,
        "z": 3
    },
    "rotation": {
        "yaw": 90
    },
    "plate": true,
    "width": 6
},
{
    "step": 17,
    "position": {
        "x": -0.5,
        "y": -0.5,
        "z": 3
    },
    "rotation": {
        "yaw": 990
    },
    "plate": true,
    "width": 6
},
{
    "step": 17,
    "position": {
        "x": -0.5,
        "z": 4
    },
    "name": "sticker",
    "text": "Place two 1x6 plates across the top to support the 2x2 battery anchor tile."
},
{
    "step": 18,
    "position": {
        "x": -5.5,
        "z": -1
    },
    "rotation": {
        "yaw": 90
    },
    "name": "windshield"
},
{
    "step": 18,
    "position": {
        "x": 4
    },
    "rotation": {
        "yaw": 90
    },
    "name": "fin",
    "text": "Add a windshield in the front and tail fin in the rear."
},
{
    "step": 19,
    "position": {
        "x": -0.5,
        "z": 4.5
    },
    "rotation": {
        "yaw": 90
    },
    "name": "battery",
    "text": "Attach the battery to the battery anchor."
},
{
    "step": 20,
    "text": "The Flat Six hexacopter is a sturdy design with plenty of extra lifting power."
}];

angular.module('flybrixAssemblyViewer').constant('flat6', JSON.stringify(flat6));

}());
