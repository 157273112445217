(function() {
    'use strict';
    
var juggernaut =
[{
    "step": 0,
    "position": {
        "z": -9
    },
    "plate": true,
    "width": 2,
    "length": 4,
    "part_number": "LEGO-3020",
    "text": "Start with a 2x4 plate."
},
{
    "step": 1,
    "position": {
        "z": -8
    },
    "name": "sticker",
    "part_number": "FB-LEGO-3068-STICKER",
    "text": "Attach the 2x2 tile with the sticker that attaches to the battery."
},
{
    "step": 2,
    "position": {
        "z": -8
    },
    "name": "frame_4x4",
    "part_number": "LEGO-64799",
    "text": "Put the 4x4 open frame around the tile."
},
{
    "step": 3,
    "position": {
        "x": -1.5,
        "z": -7
    },
    "plate": true,
    "width": 6,
    "part_number": "LEGO-3666"
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "z": -7
    },
    "plate": true,
    "width": 6,
    "part_number": "LEGO-3666",
    "text": "Attach two 1x6 tiles."
},
{
    "step": 4,
    "position": {
        "x": 1.5,
        "y": 2.5,
        "z": -6
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 4,
    "position": {
        "x": 1.5,
        "y": -2.5,
        "z": -6
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 4,
    "position": {
        "x": -1.5,
        "y": 2.5,
        "z": -6
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 4,
    "position": {
        "x": -1.5,
        "y": -2.5,
        "z": -6
    },
    "name": "stud",
    "part_number": "LEGO-4073",
    "text": "Use four 1x1 round pieces to start making space for the battery."
},
{
    "step": 5,
    "position": {
        "y": -2.5,
        "z": -5
    },
    "plate": true,
    "length": 4,
    "part_number": "LEGO-3710"
},
{
    "step": 5,
    "position": {
        "y": 2.5,
        "z": -5
    },
    "plate": true,
    "length": 4,
    "part_number": "LEGO-3710",
    "text": "Add a pair of 1x4 plates."
},
{
    "step": 6,
    "position": {
        "x": 2,
        "y": -3,
        "z": -4
    },
    "rotation": {
        "yaw": 90
    },
    "name": "joint_a",
    "comment": "front left",
    "part_number": "LEGO-73983-a"
},
{
    "step": 6,
    "position": {
        "x": 2,
        "y": -3,
        "z": -4
    },
    "rotation": {
        "yaw": 90
    },
    "offset_yaw_rotation": {
        "angle_deg": 144,
        "x": 0,
        "y": 0,
    },
    "name": "joint_b",
    "comment": "front left",
    "part_number": "LEGO-73983-b"
},
{
    "step": 6,
    "position": {
        "x": 2,
        "y": 3,
        "z": -4
    },
    "rotation": {
        "yaw": 90
    },
    "offset_yaw_rotation": {
        "angle_deg": 216,
        "x": 0,
        "y": 0,
    },
    "name": "joint_a",
    "comment": "rear left",
    "part_number": "LEGO-73983-a"
},
{
    "step": 6,
    "position": {
        "x": 2,
        "y": 3,
        "z": -4
    },
    "rotation": {
        "yaw": 90
    },
    "name": "joint_b",
    "comment": "rear left",
    "part_number": "LEGO-73983-b"
},
{
    "step": 6,
    "position": {
        "x": -2,
        "y": -3,
        "z": -4
    },
    "rotation": {
        "yaw": -90
    },
    "offset_yaw_rotation": {
        "angle_deg": 216,
        "x": 0,
        "y": 0,
    },
    "name": "joint_a",
    "comment": "front right",
    "part_number": "LEGO-73983-a"
},
{
    "step": 6,
    "position": {
        "x": -2,
        "y": -3,
        "z": -4
    },
    "rotation": {
        "yaw": -90
    },
    "name": "joint_b",
    "comment": "front right",
    "part_number": "LEGO-73983-b"
},
{
    "step": 6,
    "position": {
        "x": -2,
        "y": 3,
        "z": -4
    },
    "rotation": {
        "yaw": -90
    },
    "name": "joint_a",
    "comment": "rear right",
    "part_number": "LEGO-73983-a"
},
{
    "step": 6,
    "position": {
        "x": -2,
        "y": 3,
        "z": -4
    },
    "rotation": {
        "yaw": -90
    },
    "offset_yaw_rotation": {
        "angle_deg": 144,
        "x": 0,
        "y": 0,
    },
    "name": "joint_b",
    "comment": "rear right",
    "part_number": "LEGO-73983-b",
    "text": "Add the four 1x4 hinge pieces at the corners."
},
{
    "step": 7,
    "position": {
        "y": 2.5,
        "z": -3
    },
    "plate": true,
    "length": 4,
    "part_number": "LEGO-3710"
},
{
    "step": 7,
    "position": {
        "y": -2.5,
        "z": -3
    },
    "plate": true,
    "length": 4,
    "part_number": "LEGO-3710",
    "text": "Two more 1x4 plates should give you enough room for the battery."
},
{
    "step": 8,
    "position": {
        "z": -5.5
    },
    "rotation": {
        "yaw": 90
    },
    "name": "battery",
    "part_number": "FB-LIPO-380mAh/20C",
    "text": "This design keeps the battery low and centered for balance."
},
{
    "step": 9,
    "position": {
        "x": -1.5,
        "z": -2
    },
    "plate": true,
    "width": 6,
    "part_number": "LEGO-3666"
},
{
    "step": 9,
    "position": {
        "x": 1.5,
        "z": -2
    },
    "plate": true,
    "width": 6,
    "part_number": "LEGO-3666",
    "text": "Place two 1x6 plates over the top of the battery."
},
{
    "step": 10,
    "position": {
        "x": -1.5,
        "y": -2.5,
        "z": -1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 10,
    "position": {
        "x": -1.5,
        "y": 2.5,
        "z": -1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 10,
    "position": {
        "x": 1.5,
        "y": -2.5,
        "z": -1
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 10,
    "position": {
        "x": 1.5,
        "y": 2.5,
        "z": -1
    },
    "name": "stud",
    "part_number": "LEGO-4073",
    "text": "Place four 1x1 round pieces at the corners."
},
{
    "step": 11,
    "name": "pcb",
    "position": {
        "x": 0,
        "y": 0,
        "z": 0
    },
    "text": "The flightboard connects to the 1x1 round pieces."
},
{
    "step": 12,
    "position": {
        "x": -5,
        "y": 3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 180+144,
        "x": -1.5,
        "y": 0,
    },
    "name": "motor_arm",
    "text": "Place the first motor arm in the back right position."
},
{
    "step": 13,
    "position": {
        "x": -5,
        "y": 3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 180+144,
        "x": -1.5,
        "y": 0,
    },
    "name": "motor",
    "channel": 6,
    "ccw": true,
    "text": "Place a CCW motor (black and white wires) and connect it to channel 6 on the flightboard. Be sure to correctly align the white dots at the connector. You can wrap the wires around the motor arm to keep them out of the way."
},
{
    "step": 14,
    "position": {
        "x": -5,
        "y": 3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 180+144,
        "x": -1.5,
        "y": 0,
    },
    "name": "prop_ccw",
    "text": "Attach a CCW prop to the motor. Take a close look at the angle of the propeller blade. It needs to push air down when it spins CCW."
},
{
    "step": 15,
    "position": {
        "x": 2,
        "y": 3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 216,
        "x": -1.5,
        "y": 0,
    },
    "name": "motor_arm",
    "text": "Place the second motor arm in the back left position."
},
{
    "step": 16,
    "position": {
        "x": 2,
        "y": 3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 216,
        "x": -1.5,
        "y": 0,
    },
    "name": "motor",
    "channel": 7,
    "ccw": false,
    "text": "Place a CW motor (red and blue wires). Connect it to channel 7, being careful to align the white dots at the connector."
},
{
    "step": 17,
    "position": {
        "x": 2,
        "y": 3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 216,
        "x": -1.5,
        "y": 0,
    },
    "name": "prop_cw",
    "text": "The CW motor attached to channel 7 needs a CW propeller."
},
{
    "step": 18,
    "position": {
        "x": -5,
        "y": -3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 36,
        "x": -1.5,
        "y": 0,
    },
    "name": "motor_arm",
    "text": "Place the third motor arm in the front right position."
},
{
    "step": 19,
    "position": {
        "x": -5,
        "y": -3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 36,
        "x": -1.5,
        "y": 0,
    },
    "name": "motor",
    "channel": 0,
    "ccw": false,
    "text": "Place a CW motor (red and blue wires). Connect it to channel 0 with the white marks lined up correctly."
},
{
    "step": 20,
    "position": {
        "x": -5,
        "y": -3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 36,
        "x": -1.5,
        "y": 0,
    },
    "name": "prop_cw",
    "text": "Find a second CW type propeller for the CW motor. You can use any color you like."
},
{
    "step": 21,
    "position": {
        "x": 2,
        "y": -3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 144,
        "x": -1.5,
        "y": 0,
    },
    "name": "motor_arm",
    "text": "Place the fourth and final motor arm."
},
{
    "step": 22,
    "position": {
        "x": 2,
        "y": -3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 144,
        "x": -1.5,
        "y": 0,
    },
    "name": "motor",
    "channel": 1,
    "ccw": true,
    "text": "Place a CCW motor (black and white wires) and connect it to channel 1."
},
{
    "step": 23,
    "position": {
        "x": 2,
        "y": -3.5,
        "z": -3
    },
    "offset_yaw_rotation": {
        "angle_deg": 144,
        "x": -1.5,
        "y": 0,
    },
    "name": "prop_ccw",
    "text": "The last propeller should be CCW type to match the motor type."
},
{
    "step": 24,
    "position": {
        "x": -3.5,
        "z": -2
    },
    "plate": true,
    "width": 8,
    "part_number": "LEGO-3460"
},
{
    "step": 24,
    "position": {
        "x": 3.5,
        "z": -2
    },
    "plate": true,
    "width": 8,
    "part_number": "LEGO-3460",
    "text": "Now we'll add some pieces to make the design stronger. Attach a 1x8 plate across the motor arms on each side."
},
{
    "step": 25,
    "position": {
        "y": -3.5,
        "z": -1
    },
    "plate": true,
    "length": 8,
    "part_number": "LEGO-3460"
},
{
    "step": 25,
    "position": {
        "y": 3.5,
        "z": -1
    },
    "plate": true,
    "length": 8,
    "part_number": "LEGO-3460",
    "text": "Place two more 1x8 plates to reinforce the motor arms in the other direction."
},
{
    "step": 26,
    "position": {
        "x": -1.5,
        "y": -3.5
    },
    "name": "stud",
    "part_number": "LEGO-4073"
},
{
    "step": 26,
    "position": {
        "x": 1.5,
        "y": -3.5
    },
    "name": "stud",
    "part_number": "LEGO-4073",
    "text": "The next pieces hold the flightboard more securely in place. Start with two 1x1 round pieces."
},
{
    "step": 27,
    "position": {
        "y": -3,
        "z": 1
    },
    "rotation": {
        "yaw": 180
    },
    "name": "windshield",
    "part_number": "LEGO-3823",
    "text": "The windshield should fit over the battery wires."
},
{
    "step": 28,
    "position": {
        "y": 3.5,
        "z": 0
    },
    "plate": true,
    "length": 4,
    "part_number": "LEGO-3710",
    "text": "A 1x4 plate at the rear will have knobs at the same level as the flightboard."
},
{
    "step": 29,
    "position": {
        "y": 3,
        "z": 1
    },
    "plate": true,
    "length": 4,
    "width": 2,
    "part_number": "LEGO-3020",
    "text": "Place a 2x4 tile to tie everything together."
},
{
    "step": 30,
    "position": {
        "y": 3.5,
        "z": 2
    },
    "rotation": {
        "yaw": 180
    },
    "name": "fin",
    "part_number": "LEGO-44661",
    "text": "Place the tail fin as a finishing touch!"
},
{
    "step": 31,
    "text": "The Juggernaut is a stable and balanced quadcopter. We suggest you start with this design while you are learning to fly."
}];

angular.module('flybrixAssemblyViewer').constant('juggernaut', JSON.stringify(juggernaut));

}());
