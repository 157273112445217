(function() {
    'use strict';
    
var flyingV =
[{
    "step": 0,
    "position": {
        "x": 3.5,
        "z": -4
    },
    "plate": true,
    "width": 4
},
{
    "step": 0,
    "position": {
        "x": -3.5,
        "z": -4
    },
    "plate": true,
    "width": 4,
    "text": "Start with two 1x4 plates."
},
{
    "step": 1,
    "position": {
        "y": 1.5,
        "z": -3
    },
    "plate": true,
    "length": 8
},
{
    "step": 1,
    "position": {
        "y": -1.5,
        "z": -3
    },
    "plate": true,
    "length": 8,
    "text": "Connect two 1x8 plates to make a rectangle."
},
{
    "step": 2,
    "position": {
        "x": -2,
        "y": -2,
        "z": -2
    },
    "rotation": {
        "yaw": 90
    },
    "comment": "rear right",
    "name": "joint_a"
},
{
    "step": 2,
    "position": {
        "x": -2,
        "y": -2,
        "z": -2
    },
    "rotation": {
        "yaw": 115.54
    },
    "comment": "rear right",
    "name": "joint_b"
},
{
    "step": 2,
    "position": {
        "x": -2,
        "y": 2,
        "z": -2
    },
    "rotation": {
        "yaw": 90
    },
    "comment": "rear left",
    "name": "joint_b"
},
{
    "step": 2,
    "position": {
        "x": -2,
        "y": 2,
        "z": -2
    },
    "rotation": {
        "yaw": 64.46
    },
    "comment": "rear left",
    "name": "joint_a"
},
{
    "step": 2,
    "position": {
        "x": 1,
        "y": 2,
        "z": -2
    },
    "rotation": {
        "yaw": -90
    },
    "comment": "front left",
    "name": "joint_a"
},
{
    "step": 2,
    "position": {
        "x": 1,
        "y": 2,
        "z": -2
    },
    "rotation": {
        "yaw": -38.16
    },
    "comment": "front left",
    "name": "joint_b"
},
{
    "step": 2,
    "position": {
        "x": 1,
        "y": -2,
        "z": -2
    },
    "rotation": {
        "yaw": -141.84
    },
    "comment": "front right",
    "name": "joint_a"
},
{
    "step": 2,
    "position": {
        "x": 1,
        "y": -2,
        "z": -2
    },
    "rotation": {
        "yaw": -90
    },
    "comment": "front right",
    "name": "joint_b",
    "text": "Place four 1x4 hinge plates as shown."
},
{
    "step": 3,
    "position": {
        "x": -3.5,
        "y": -1.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "y": -1.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 3,
    "position": {
        "x": -3.5,
        "y": 1.5,
        "z": -1
    },
    "name": "stud"
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "y": 1.5,
        "z": -1
    },
    "name": "stud",
    "text": "Attach four 1x1 round pieces. These parts will support the flightboard."
},
{
    "step": 4,
    "rotation": {
        "yaw": 90
    },
    "name": "pcb",
    "position": {
        "x": -1,
        "y": 0,
        "z": 0
    },
    "text": "Attach the flightboard to the 1x1 round pieces."
},
{
    "step": 5,
    "position": {
        "x": -4.7,
        "y": 3.8,
        "z": -1
    },
    "rotation": {
        "yaw": -25.54
    },
    "comment": "rear left",
    "name": "motor_arm"
},
{
    "step": 5,
    "position": {
        "x": -4.7,
        "y": -3.8,
        "z": -1
    },
    "rotation": {
        "yaw": 25.54
    },
    "comment": "rear right",
    "name": "motor_arm"
},
{
    "step": 5,
    "position": {
        "x": 2.6,
        "y": -4.85,
        "z": -1
    },
    "rotation": {
        "yaw": -231.84
    },
    "comment": "front right",
    "name": "motor_arm"
},
{
    "step": 5,
    "position": {
        "x": 2.6,
        "y": 4.85,
        "z": -1
    },
    "rotation": {
        "yaw": 231.84
    },
    "name": "motor_arm",
    "comment": "front left",
    "text": "Attach straight motor arms to each of the hinges."
},
{
    "step": 6,
    "position": {
        "x": -4.7,
        "y": -3.8,
        "z": -1
    },
    "rotation": {
        "yaw": 25.54
    },
    "name": "motor",
    "channel": 6,
    "ccw": true,
    "text": "Install a CCW motor (white/black wires) in the rear right position and connect it to channel 6. Be sure to line up the white marks on the connector with the corresponding marks on the flightboard."
},
{
    "step": 7,
    "position": {
        "x": -4.7,
        "y": -3.8,
        "z": -1
    },
    "rotation": {
        "yaw": 25.54
    },
    "name": "prop_ccw",
    "text": "Attach a CCW type propeller to the CCW motor."
},
{
    "step": 8,
    "position": {
        "x": -4.7,
        "y": 3.8,
        "z": -1
    },
    "rotation": {
        "yaw": -25.54
    },
    "name": "motor",
    "channel": 7,
    "ccw": false,
    "text": "Install a CW motor (red/blue wires) and a CW propeller in the rear left position. Attach the motor to channel 7."
},
{
    "step": 8,
    "position": {
        "x": -4.7,
        "y": 3.8,
        "z": -1
    },
    "rotation": {
        "yaw": -25.54
    },
    "name": "prop_cw"
},
{
    "step": 9,
    "position": {
        "x": 2.6,
        "y": -4.85,
        "z": -1
    },
    "rotation": {
        "yaw": -231.84
    },
    "name": "motor",
    "channel": 0,
    "ccw": false,
    "text": "Install a CW motor at the front right position and attach it to channel 0."
},
{
    "step": 10,
    "position": {
        "x": 2.6,
        "y": -4.85,
        "z": -1
    },
    "rotation": {
        "yaw": -231.84
    },
    "name": "prop_cw",
    "text": "Pair the front right motor with a CW type propeller."
},
{
    "step": 11,
    "position": {
        "x": 2.6,
        "y": 4.85,
        "z": -1
    },
    "rotation": {
        "yaw": 231.84
    },
    "name": "motor",
    "channel": 1,
    "ccw": true,
    "text": "The front left motor and propeller should be CCW. Attach the motor connector to channel 1."
},
{
    "step": 11,
    "position": {
        "x": 2.6,
        "y": 4.85,
        "z": -1
    },
    "rotation": {
        "yaw": 231.84
    },
    "name": "prop_ccw"
},
{
    "step": 12,
    "position": {
        "x": -4.3,
        "y": -3.6
    },
    "name": "stud"
},
{
    "step": 12,
    "position": {
        "x": -4.3,
        "y": 3.6
    },
    "name": "stud",
    "text": "Attach 1x1 round pieces on the third knob of the rear two motor arms."
},
{
    "step": 13,
    "position": {
        "x": 2.9,
        "y": -5.2
    },
    "name": "stud"
},
{
    "step": 13,
    "position": {
        "x": 2.9,
        "y": 5.2
    },
    "name": "stud",
    "text": "On the front motor arms, attach 1x1 round pieces on the fourth knob positions."
},
{
    "step": 14,
    "position": {
        "x": -0.7,
        "y": -4.4,
        "z": 1
    },
    "rotation": {
        "yaw": -12.63
    },
    "plate": true,
    "length": 8
},
{
    "step": 14,
    "position": {
        "x": -0.7,
        "y": 4.4,
        "z": 1
    },
    "rotation": {
        "yaw": 12.63
    },
    "plate": true,
    "length": 8,
    "text": "Move the arms so that two 1x8 plates connect the 1x1 round pieces."
},
{
    "step": 15,
    "position": {
        "x": -4.3,
        "y": -0.1,
        "z": 1
    },
    "rotation": {
        "yaw": 90
    },
    "plate": true,
    "length": 8,
    "text": "Tie the rear arms together with a third 1x8 plate."
},
{
    "step": 16,
    "position": {
        "x": -3.8,
        "z": 2
    },
    "name": "sticker",
    "text": "Mount the 2x2 battery anchor tile at the rear of the flightboard."
},
{
    "step": 17,
    "position": {
        "x": -3.8,
        "z": 4.5
    },
    "rotation": {
        "yaw": 90
    },
    "name": "battery",
    "text": "Attach a fully charged battery."
},
{
    "step": 18,
    "position": {
        "x": 4,
        "z": -2
    },
    "rotation": {
        "yaw": -90
    },
    "name": "windshield",
    "text": "Add the windshield at the front as a finishing touch."
},
{
    "step": 19,
    "text": "The Flying V design is a quadcopter with less symmetry than some of the other designs. Build it to see how this changes flight performance!"
}];

angular.module('flybrixAssemblyViewer').constant('flyingV', JSON.stringify(flyingV));

}());
