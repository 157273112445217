(function() {
    'use strict';
    
var angleArmed =
[{
    "step": 0,
    "position": {
        "z": -7
    },
    "plate": true,
    "width": 2,
    "length": 4
},
{
    "step": 0,
    "position": {
        "z": -6
    },
    "name": "sticker",
    "text": "Start by connecting the 2x2 battery anchor tile to a 2x4 plate."
},
{
    "step": 1,
    "position": {
        "x": -1.5,
        "y": -0.5,
        "z": -6
    },
    "name": "stud"
},
{
    "step": 1,
    "position": {
        "x": 1.5,
        "y": -0.5,
        "z": -6
    },
    "name": "stud"
},
{
    "step": 1,
    "position": {
        "x": -1.5,
        "y": 0.5,
        "z": -6
    },
    "name": "stud"
},
{
    "step": 1,
    "position": {
        "x": 1.5,
        "y": 0.5,
        "z": -6
    },
    "name": "stud",
    "text": "Place two 1x1 round pieces on each side."
},
{
    "step": 2,
    "position": {
        "x": 1.5,
        "z": -5
    },
    "plate": true,
    "width": 6,
    "length": 1
},
{
    "step": 2,
    "position": {
        "x": -1.5,
        "z": -5
    },
    "plate": true,
    "width": 6,
    "length": 1,
    "text": "Attach two 1x6 plates."
},
{
    "step": 3,
    "position": {
        "y": 2.5,
        "z": -4
    },
    "plate": true,
    "width": 1,
    "length": 4
},
{
    "step": 3,
    "position": {
        "y": -2.5,
        "z": -4
    },
    "plate": true,
    "width": 1,
    "length": 4
},
{
    "step": 3,
    "position": {
        "x": -1.5,
        "y": -2.5,
        "z": -3
    },
    "name": "stud"
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "y": -2.5,
        "z": -3
    },
    "name": "stud"
},
{
    "step": 3,
    "position": {
        "x": -1.5,
        "y": 2.5,
        "z": -3
    },
    "name": "stud"
},
{
    "step": 3,
    "position": {
        "x": 1.5,
        "y": 2.5,
        "z": -3
    },
    "name": "stud",
    "text": "Start to make space for the battery with two 1x4 plates and four 1x1 round pieces."
},
{
    "step": 4,
    "position": {
        "y": 2.5,
        "z": -2
    },
    "plate": true,
    "width": 1,
    "length": 6
},
{
    "step": 4,
    "position": {
        "y": -2.5,
        "z": -2
    },
    "plate": true,
    "width": 1,
    "length": 6
},
{
    "step": 4,
    "position": {
        "z": -3.5
    },
    "rotation": {
        "yaw": 90
    },
    "name": "battery",
    "text": "Attach a fully charged battery and two 1x6 plates."
},
{
    "step": 5,
    "position": {
        "x": 2.5,
        "y": -2.5,
        "z": -1
    },
    "name": "motor_arm_angled_right"
},
{
    "step": 5,
    "position": {
        "x": 2.5,
        "y": 2.5,
        "z": -1
    },
    "name": "motor_arm_angled_left"
},
{
    "step": 5,
    "position": {
        "x": -2.5,
        "y": 2.5,
        "z": -1
    },
    "rotation": {
        "yaw": 180
    },
    "name": "motor_arm_angled_right"
},
{
    "step": 5,
    "position": {
        "x": -2.5,
        "y": -2.5,
        "z": -1
    },
    "rotation": {
        "yaw": 180
    },
    "name": "motor_arm_angled_left",
    "text": "Attach the four angled motor arms."
},
{
    "step": 6,
    "name": "pcb",
    "position": {
        "x": 0,
        "y": 0,
        "z": 0
    },
    "text": "Place the flightboard."
},
{
    "step": 7,
    "position": {
        "x": -6.4,
        "y": -4.05,
        "z": 4
    },
    "rotation": {
        "yaw": 60
    },
    "name": "motor",
    "channel": 0,
    "ccw": false,
    "text": "Install a CW motor (red and blue wires) in the front right position and connect it to channel 0. Pair the motor with a CW propeller."
},
{
    "step": 7,
    "position": {
        "x": -6.4,
        "y": -4.05,
        "z": 4
    },
    "rotation": {
        "yaw": 60
    },
    "name": "prop_cw"
},
{
    "step": 8,
    "position": {
        "x": 6.4,
        "y": -4.05,
        "z": 4
    },
    "rotation": {
        "yaw": 120
    },
    "name": "motor",
    "channel": 1,
    "ccw": true,
    "text": "Install a CCW motor (black and white wires) in the front left position and connect it to channel 1. Pair this motor with a CCW propeller."
},
{
    "step": 8,
    "position": {
        "x": 6.4,
        "y": -4.05,
        "z": 4
    },
    "rotation": {
        "yaw": 120
    },
    "name": "prop_ccw"
},
{
    "step": 9,
    "position": {
        "x": 6.4,
        "y": 4.05,
        "z": 4
    },
    "rotation": {
        "yaw": -120
    },
    "name": "motor",
    "channel": 7,
    "ccw": true,
    "text": "The rear left position gets a CCW motor and propeller. Attach the motor to channel 7, taking care to align the white marks on the connector."
},
{
    "step": 9,
    "position": {
        "x": 6.4,
        "y": 4.05,
        "z": 4
    },
    "rotation": {
        "yaw": -120
    },
    "name": "prop_ccw"
},
{
    "step": 10,
    "position": {
        "x": -6.4,
        "y": 4.05,
        "z": 4
    },
    "rotation": {
        "yaw": -60
    },
    "name": "motor",
    "channel": 6,
    "ccw": false,
    "text": "The final motor and propeller are CW. The motor should be connected to channel 6."
},
{
    "step": 10,
    "position": {
        "x": -6.4,
        "y": 4.05,
        "z": 4
    },
    "rotation": {
        "yaw": -60
    },
    "name": "prop_cw"
},
{
    "step": 11,
    "position": {
        "x": -1.5,
        "y": -2.5,
        "z": 1
    },
    "name": "stud"
},
{
    "step": 11,
    "position": {
        "x": 1.5,
        "y": -2.5,
        "z": 1
    },
    "name": "stud"
},
{
    "step": 11,
    "position": {
        "y": 2.5,
        "z": 1
    },
    "plate": true,
    "width": 1,
    "length": 4,
    "text": "Use two 1x1 round pieces and one 1x4 plate to add connection points for the windshield and tail fin."
},
{
    "step": 12,
    "position": {
        "x": 0,
        "y": -3,
        "z": 3
    },
    "plate": true,
    "width": 2,
    "length": 4
},
{
    "step": 12,
    "position": {
        "y": 2.5,
        "z": 2
    },
    "rotation": {
        "yaw": -180
    },
    "name": "fin"
},
{
    "step": 12,
    "position": {
        "y": -3,
        "z": 4
    },
    "rotation": {
        "yaw": 180
    },
    "name": "windshield",
    "text": "Add the windshield and the tail fin for the finishing touches."
},
{
    "step": 13,
    "text": "The Angle Armed design has a low center of balance. Build it to see how this changes flight performance!"
}];

angular.module('flybrixAssemblyViewer').constant('angleArmed', JSON.stringify(angleArmed));

}());
